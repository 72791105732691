import { AccountInfo, PlayerId, PrettyPlayer } from "@ollie-sports/models";
import { Prisma } from "@prisma/client";
import moment from "moment";
import { getFlexPrismaClient } from "../../../helpers";
import { maybeAddFlexPlayerProfileImage } from "./maybeAddFlexPlayerProfileImage";
import { updateFlexProfileImage } from "./updateFlexProfileImage";

export async function refreshFlexPlayer(p: {
  flexPlayerId: number;
  flexUserId: number;
  playerInfo: { accountInfo: AccountInfo; playerId: PlayerId };
}) {
  const prismaClient = getFlexPrismaClient();
  const timestamp = moment().toDate();
  const playerUpdatePromises: Promise<any>[] = [];
  const playerProfileImageFile = await prismaClient.files.findFirst({
    where: {
      attachable_id: p.flexUserId,
      attachable_type: "App\\Models\\User"
    }
  });
  await prismaClient.players.update({
    where: { id: p.flexPlayerId },
    data: {
      first_name: p.playerInfo.accountInfo.firstName,
      last_name: p.playerInfo.accountInfo.lastName,
      phone: p.playerInfo.accountInfo.phoneNumber,
      updated_at: timestamp
    }
  });
  await prismaClient.users.update({
    where: { id: p.flexUserId },
    data: {
      first_name: p.playerInfo.accountInfo.firstName,
      last_name: p.playerInfo.accountInfo.lastName,
      phone: p.playerInfo.accountInfo.phoneNumber,
      updated_at: timestamp
    }
  });

  if (p.playerInfo.accountInfo.profileImageUri) {
    if (!playerProfileImageFile) {
      await maybeAddFlexPlayerProfileImage(p);
    } else {
      await updateFlexProfileImage({
        flexFileId: Number(playerProfileImageFile.id),
        playerInfo: p.playerInfo
      });
    }
  }
  return playerUpdatePromises;
}
