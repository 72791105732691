import { TeamId, FundraisingPrize } from "@ollie-sports/models";
import moment from "moment";
import { getFlexPrismaClient } from "../../helpers";

export async function flex__server__addTeamPrize(p: {
  ollieTeamId: TeamId;
  flexTeamId: number;
  fundraiserId: number;
  prize: Omit<FundraisingPrize, "prizeId">;
}) {
  const prismaClient = getFlexPrismaClient();
  const timestamp = moment().toDate();

  const newPrize = await prismaClient.prizes.create({
    data: {
      name: p.prize.name,
      description: p.prize.description,
      type: p.prize.type,
      is_custom: p.prize.isCustom,
      amount: p.prize.amount ?? 0,
      updated_at: timestamp,
      created_at: timestamp,
      app: "ollies"
    }
  });

  await prismaClient.fundraiser_prizes.create({
    data: {
      fundraiser_id: p.fundraiserId,
      prize_id: Number(newPrize.id),
      created_at: timestamp,
      updated_at: timestamp
    }
  });
}
flex__server__addTeamPrize.auth = () => {};
