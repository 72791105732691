import { TeamFundraisingData, TeamId } from "@ollie-sports/models";
import _ from "lodash";
import { getTeamFundraiserData } from "./helpers/getTeamFundraiserData";

export async function flex__server__getTeamFundraiserData(p: {
  teamId: TeamId;
  flexTeamId: number;
}): Promise<TeamFundraisingData | null> {
  return getTeamFundraiserData(p);
}

flex__server__getTeamFundraiserData.auth = () => {};
