import { FundraisingPrize, PRIZE_TYPES } from "@ollie-sports/models";

export function adjustNonAmountFundraisingPrizeAmounts(p: { prizes: FundraisingPrize[] }) {
  return p.prizes.map(pz => {
    if (pz.type === PRIZE_TYPES.playerAmount || pz.type === PRIZE_TYPES.teamAmount) {
      return pz;
    } else {
      return { ...pz, amount: Math.max(pz.amount / 100, 1) };
    }
  });
}
