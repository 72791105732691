import { TeamId, OrgId, ORG_FUNDRAISER_VISIBILITY_STATUS } from "@ollie-sports/models";
import { Prisma, PrismaClient } from "@prisma/client";
import moment from "moment";
import { getUniversalHelpers, getFlexPrismaClient, bigIntToObject } from "../../helpers";
import { player__universal__fetchPlayersOnATeam } from "../player.api";
import { cloneFlexTeam } from "./helpers/cloneFlexTeam";
import { createFlexPlayersFromOlliePlayers } from "./helpers/createFlexPlayersFromOlliePlayers";
import { linkOllieTeamToFlexTeam } from "./helpers/linkOllieTeamToFlexTeam";

export async function flex__server__createAndLinkFlexTeamToOllieTeamFromOrgTemplateTeam(p: {
  ollieTeamId: TeamId;
  orgFundraiserId: number;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const prismaClient = getFlexPrismaClient();

  const [team, prettyPlayers, flexTeamMapping, ollieOrgFundraiserMapping] = await Promise.all([
    h.Team.getDoc(p.ollieTeamId),
    player__universal__fetchPlayersOnATeam({
      teamId: p.ollieTeamId
    }),
    prismaClient.ollie_team_mapping.findFirst({
      where: {
        ollie_team_id: p.ollieTeamId,
        ollie_org_fundraiser_id: p.orgFundraiserId
      }
    }),
    prismaClient.ollie_org_fundraiser_mapping.findFirst({
      where: {
        id: p.orgFundraiserId
      }
    })
  ]);

  if (flexTeamMapping) {
    throw new Error(`Team has already been created in flex: ${p.ollieTeamId}`);
  }

  if (!team) {
    throw new Error(`Cannot create flex team for teamId: ${p.ollieTeamId}`);
  }

  if (!ollieOrgFundraiserMapping) {
    throw new Error(`Cannot find org fundraiser for id: ${p.orgFundraiserId}`);
  }

  const { newTeam, newFundraiser } = await cloneFlexTeam({
    flexTeamId: Number(ollieOrgFundraiserMapping.flex_team_id),
    ollieTeam: team,
    prettyPlayers
  });

  await linkOllieTeamToFlexTeam({
    flexTeamId: Number(newTeam.id),
    ollieTeam: team,
    orgId: team?.orgId,
    prettyPlayers,
    fundraiserEndDate: moment(ollieOrgFundraiserMapping.fundraiser_end_date).valueOf(),
    fundraiserVisibilityStatus: ollieOrgFundraiserMapping.fundraiser_visibility_status as ORG_FUNDRAISER_VISIBILITY_STATUS,
    ollieOrgFundraiserId: Number(ollieOrgFundraiserMapping.id),
    fundsGoToTeam: ollieOrgFundraiserMapping.funds_go_to_team
  });

  await createFlexPlayersFromOlliePlayers({
    prettyPlayers: prettyPlayers,
    flexTeamId: Number(newTeam.id),
    teamId: p.ollieTeamId,
    orgId: team.orgId,
    goalAmount: newFundraiser?.individual_goal_amount // Todo figure out goal stuff?
  });
}

flex__server__createAndLinkFlexTeamToOllieTeamFromOrgTemplateTeam.auth = () => {};
