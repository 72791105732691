import { AccountId, PlayerId } from "@ollie-sports/models";
import { getServerHelpers } from "../../../helpers";
import _ from "lodash";

export async function getAccountIdsManagingPlayerIds(p: { playerIds: PlayerId[] }) {
  const queryParams = [p.playerIds];
  const query = `select p.id as player_id, (jsonb_each(pb.item -> 'managingAccounts')).key as account_id
  from mirror_player p,
       mirror_playerbundle pb
  where pb.id = p.item ->> 'linkedPlayerBundleId'
    and p.id = any ($1::text[]);`;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  const accountIdData = r1.rows.map(row => {
    return {
      playerId: row["player_id"],
      accountId: row["account_id"]
    };
  }) as { playerId: PlayerId; accountId: AccountId }[];

  return accountIdData.reduce((acc, val) => {
    if (acc[val.playerId]) {
      const currentAccountIds = acc[val.playerId];
      acc[val.playerId] = _.uniq([...currentAccountIds, val.accountId]);
    } else {
      acc[val.playerId] = [val.accountId];
    }
    return acc;
  }, {} as Record<PlayerId, AccountId[]>);
}
