import { TeamId, FundraisingPrize } from "@ollie-sports/models";
import moment from "moment";
import { getFlexPrismaClient } from "../../helpers";

export async function flex__server__updatePlayerShareMessage(p: { flexPlayerId: number; newShareMessage: string }) {
  const prismaClient = getFlexPrismaClient();
  const timestamp = moment().toDate();

  await prismaClient.players.update({
    where: {
      id: p.flexPlayerId
    },
    data: {
      share_sms: p.newShareMessage,
      share_email: p.newShareMessage,
      updated_at: timestamp
    }
  });
}
flex__server__updatePlayerShareMessage.auth = () => {};
