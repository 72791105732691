import {
  FundraiserFinalPlayerRaffleTotalDetails,
  FundraiserFinalPlayerRaffleTotalDetailsPlusPrizeWon
} from "@ollie-sports/models";

export function chooseRaffleWinners(p: {
  allRaffleTotals: FundraiserFinalPlayerRaffleTotalDetails[];
  prizeCSV: string;
}): FundraiserFinalPlayerRaffleTotalDetailsPlusPrizeWon[] {
  const raffleWinners: FundraiserFinalPlayerRaffleTotalDetailsPlusPrizeWon[] = [];
  let allRaffleNames: FundraiserFinalPlayerRaffleTotalDetails[] = [];
  p.allRaffleTotals.forEach(a => {
    for (let i = 0; i < a.numRaffleTicketsEarned; i++) {
      allRaffleNames.push(a);
    }
  });

  const prizeNames = p.prizeCSV.split(",");

  prizeNames.forEach(prize => {
    if (allRaffleNames.length) {
      const randomNumber = Math.floor(Math.random() * allRaffleNames.length);
      const winnerDetails = allRaffleNames[randomNumber];
      raffleWinners.push({ ...winnerDetails, prizeWon: prize });
      allRaffleNames = allRaffleNames.filter(a => a.playerId !== winnerDetails.playerId);
    }
  });

  return raffleWinners;
}
