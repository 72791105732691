import { OrgId, PrettyPlayer, TeamId } from "@ollie-sports/models";
import moment from "moment";
import { getFlexPrismaClient } from "../../../helpers";
import { v4 as uuid } from "uuid";
import { Prisma } from "@prisma/client";
import { maybeAddFlexPlayerProfileImage } from "./maybeAddFlexPlayerProfileImage";

export async function createFlexPlayerFromOlliePrettyPlayer(p: {
  prettyPlayer: PrettyPlayer;
  flexTeamId: number;
  goalAmount?: number;
  teamId: TeamId;
  orgId?: OrgId;
}) {
  if (p.prettyPlayer.player.guestPlayerExirationMS) {
    return;
  }
  const timestamp = moment().toDate();
  const prismaClient = getFlexPrismaClient();
  const [playerUser, fundraiser] = await Promise.all([
    prismaClient.users.create({
      data: {
        email: `${p.prettyPlayer.player.id.replace("-", "")}@${moment().valueOf()}.com`,
        first_name: p.prettyPlayer.derived.accountInfo.firstName,
        last_name: p.prettyPlayer.derived.accountInfo.lastName,
        password: uuid(),
        type: "player",
        uuid: uuid(),
        created_at: timestamp,
        updated_at: timestamp,
        phone: p.prettyPlayer.derived.accountInfo.phoneNumber
      }
    }),
    prismaClient.fundraisers.findFirst({
      where: {
        team_id: p.flexTeamId
      }
    })
  ]);

  const player = await prismaClient.players.create({
    data: {
      team_id: p.flexTeamId,
      uuid: uuid(),
      created_at: timestamp,
      updated_at: timestamp,
      email: playerUser.email,
      first_name: playerUser.first_name,
      last_name: playerUser.last_name,
      goal_amount: p.goalAmount,
      phone: playerUser.phone,
      share_email: fundraiser?.share_email,
      share_sms: fundraiser?.share_sms,
      user_id: Number(playerUser.id)
    }
  });

  await maybeAddFlexPlayerProfileImage({
    flexPlayerId: Number(player.id),
    flexUserId: Number(playerUser.id),
    playerInfo: {
      accountInfo: p.prettyPlayer.derived.accountInfo,
      playerId: p.prettyPlayer.player.id
    }
  });

  await prismaClient.ollie_player_mapping.create({
    data: {
      created_at: timestamp,
      updated_at: timestamp,
      flex_player_id: player.id,
      ollie_player_id: p.prettyPlayer.player.id,
      ollie_team_id: p.teamId,
      ollie_org_id: p.orgId
    }
  });
}
