import { TeamId, FundraisingPrize, FundraisingReferral, AccountId } from "@ollie-sports/models";
import moment from "moment";
import { getFlexPrismaClient } from "../../helpers";

export async function flex__server__addReferral(p: {
  flexPlayerId?: number;
  accountId: AccountId;
  referral: FundraisingReferral;
}) {
  const prismaClient = getFlexPrismaClient();
  const timestamp = moment().toDate();

  await prismaClient.referrals.create({
    data: {
      entity_name: p.referral.entityName,
      name: p.referral.name,
      phone: p.referral.phone,
      referred_by_player_id: p.flexPlayerId,
      created_at: timestamp,
      updated_at: timestamp,
      ollie_account_id: p.accountId
    }
  });
}
flex__server__addReferral.auth = () => {};
