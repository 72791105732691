import { getFlexPrismaClient } from "../../helpers";

export async function flex__server__updatePlayerGoal(p: { flexPlayerId: number; newGoal: number }) {
  const prismaClient = getFlexPrismaClient();
  await prismaClient.players.update({
    where: {
      id: p.flexPlayerId
    },
    data: { goal_amount: p.newGoal }
  });
}

flex__server__updatePlayerGoal.auth = () => {};
