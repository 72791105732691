import { getFlexPrismaClient } from "../../helpers";

export async function flex__server__mysqlTest(): Promise<string> {
  try {
    const prismaClient = getFlexPrismaClient();
    const userCount = await prismaClient.users.count();

    return `Mysql Test Result: userCount: ${userCount}`;
  } catch (e: any) {
    return `Mysql Test Result: error - ${e.msg}`;
  }
}
