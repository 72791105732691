import { OlliePipe } from "@ollie-sports/pipe";
import { Request } from "express";
import moment from "moment";
import { getServerHelpers, getUniversalHelpers, getFlexPrismaClient } from "../../helpers";

export async function flex__server__checkForFundraisersToEnd() {
  const prismaClient = getFlexPrismaClient();
  const { olliePipe } = getUniversalHelpers();
  const timestamp = moment().toDate();

  const timeRangeLow = moment().subtract(5, "minute").toDate();
  const timeRangeHigh = moment().add(5, "minute").toDate();

  // End club fundraisers
  const orgFundraisersToEnd = await prismaClient.ollie_org_fundraiser_mapping.findMany({
    where: {
      fundraiser_end_date: {
        gt: timeRangeLow,
        lt: timeRangeHigh
      }
    }
  });

  for (let i = 0; i < orgFundraisersToEnd.length; i++) {
    let fundraiserMapping = orgFundraisersToEnd[i];
    await Promise.all([
      prismaClient.fundraisers.updateMany({
        where: {
          team_id: Number(fundraiserMapping.flex_team_id)
        },
        data: {
          status: "inactive",
          updated_at: timestamp
        }
      }),
      prismaClient.$queryRaw`update fundraisers f inner join ollie_team_mapping otm on otm.flex_team_id = f.team_id
        set status = ${"inactive"},
            f.updated_at = NOW()
        where otm.ollie_org_fundraiser_id = ${fundraiserMapping.id};`
    ]);

    olliePipe.emitEvent({
      type: "metric-end-fundraiser",
      payload: {
        ollieOrgFundraiserId: fundraiserMapping.id,
        scheduledEndDate: fundraiserMapping.fundraiser_end_date,
        actualTimeEnded: timestamp
      }
    });
  }
}

flex__server__checkForFundraisersToEnd.auth = (req: Request) => {
  // Magic token must be included in all requests. Not too important to be super secure since presumably this is a short term endpoint
  if (req.get("x-magic-token") !== "LY:82vV$~uhk<N^") {
    throw new Error("Need magic token!");
  }
};
