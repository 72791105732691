import { FundraiserFinalPlayerRaffleTotalDetails, FundraisingOrgTopPlayer, FundraisingPrize, TeamId } from "@ollie-sports/models";
import _ from "lodash";
import { getFinalRaffleTicketCountForTeam } from "./helpers/getFinalRaffeTicketCountForTeam";

export async function flex__server__getFinalRaffleTicketCountForTeam(p: {
  flexTeamId: number;
  teamId: TeamId;
  orgPrizes?: FundraisingPrize[];
  orgPlayers?: FundraisingOrgTopPlayer[];
  orgFundraiserId?: number;
}): Promise<FundraiserFinalPlayerRaffleTotalDetails[]> {
  return getFinalRaffleTicketCountForTeam(p);
}

flex__server__getFinalRaffleTicketCountForTeam.auth = () => {};
