import { TeamId, FundraisingPrize, FundraisingReferral, AccountId } from "@ollie-sports/models";
import moment from "moment";
import { getFlexPrismaClient } from "../../helpers";

export async function flex__server__getReferralsForAccount(p: { accountId: AccountId }): Promise<FundraisingReferral[]> {
  const prismaClient = getFlexPrismaClient();

  const referrals = await prismaClient.referrals.findMany({
    where: {
      ollie_account_id: p.accountId
    }
  });

  return referrals.map(r => {
    return {
      entityName: r.entity_name,
      name: r.name,
      phone: r.phone,
      referralId: Number(r.id)
    };
  });
}
flex__server__getReferralsForAccount.auth = () => {};
