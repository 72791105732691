import { FundraisingOrgTopPlayer, FundraisingOrgTopTeam, FundraisingPrize, OrgFundraisingData } from "@ollie-sports/models";
import { getFlexPrismaClient, getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import { adjustNonAmountFundraisingPrizeAmounts } from "./helpers/adjustNonAmountFundraisingPrizeAmounts";

export async function flex__server__getOrgFundraiserData(p: { orgFundraiserId: number }): Promise<OrgFundraisingData | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const prismaClient = getFlexPrismaClient();

  const [teamAmountsRaised, topPlayers, playerData, orgPrizes, orgFundraiserMapping] = await Promise.all([
    prismaClient.$queryRaw<FundraisingOrgTopTeam[]>`select h.amount as amount,
    h.teamId as teamId,
    h.name as name,
    h.flexTeamId as flexTeamId,
    h.pageViews as pageViews,
    coalesce(round(h.amount / h.numPlayers, 2), 0) as avgPerPlayer,
    RANK() OVER (
        ORDER BY round(h.amount / h.numPlayers, 2) desc
        )                                place
from (select coalesce(sum(don.amount), 0) as amount,
          g.fundraiserId,
          g.flexTeamId,
          g.numPlayers,
          g.teamId,
          g.name,
          g.pageViews
   from (select a.ollieTeamId            as teamId,
                a.name                   as name,
                a.flexTeamId             as flexTeamId,
                coalesce(b.pageViews, 0) as pageViews,
                b.numPlayers             as numPlayers,
                a.fundraiserId
         from (select otm.ollie_team_id           as ollieTeamId,
                      otm.flex_team_id            as flexTeamId,
                      t.name                      as name,
                      otm.ollie_org_fundraiser_id as ollieOrgFundraiserId,
                      fr.id                       as fundraiserId
               from ollie_team_mapping otm,
                    teams t,
                    fundraisers fr
               where t.id = otm.flex_team_id
                 and fr.team_id = otm.flex_team_id) a
                  left join
              (
                  select f.teamId, sum(f.pageViews) as pageViews, count(*) as numPlayers
                  from (select COALESCE(e.amount, 0)                              as amount,
                               e.teamId,
                               count(distinct if(pv.id is not null, pv.id, null)) as pageViews
                        from (select sum(d.amount) as amount, c.teamId as teamId, c.playerId as playerId
                              from (select p.id as playerId, p.team_id as teamId from players p) c
                                       left join donations d
                                                 on d.referred_by = c.playerId
                              group by c.teamId, c.playerId) e
                                 left join page_views pv
                                           on e.playerId = pv.viewable_id and viewable_type = 'App\\\\Models\\\\Player'
                        group by e.amount, e.playerId, e.teamId) f
                  group by f.teamId
              ) b on b.teamId = a.flexTeamId
         where a.ollieOrgFundraiserId = ${p.orgFundraiserId}) g
            left join donations don on don.fundraiser_id = g.fundraiserId
   group by g.fundraiserId, g.flexTeamId, g.numPlayers, g.teamId, g.name, g.pageViews) h;`,
    prismaClient.$queryRaw<FundraisingOrgTopPlayer[]>`select c.*, count(distinct if(pv.id is not null, pv.id, null)) as pageViews
    from (select b.playerId,
                 b.amount,
                 b.flexTeamId,
                 RANK() OVER (
                     ORDER BY amount desc
                     ) place,
                 b.flexPlayerId
          from (select a.olliePlayerId as playerId, coalesce(sum(d.amount), 0) as amount, a.flexTeamId as flexTeamId, a.flexPlayerId
                from (select p.id as flexPlayerId, opm.ollie_player_id as olliePlayerId, otm.flex_team_id as flexTeamId
                      from players p,
                           ollie_team_mapping otm,
                           ollie_player_mapping opm
                      where p.team_id = otm.flex_team_id
                        and opm.flex_player_id = p.id
                        and otm.ollie_org_fundraiser_id = ${p.orgFundraiserId}) a
                         left join donations d on d.referred_by = a.flexPlayerId
                group by a.olliePlayerId, a.flexTeamId, a.flexPlayerId
                order by coalesce(sum(d.amount), 0) desc
                limit 100) b
          where b.amount > 0) c
             left join page_views pv on pv.viewable_id = c.flexPlayerId and viewable_type = 'App\\\\Models\\\\Player' group by c.flexPlayerId, c.amount, c.flexTeamId, c.playerId, c.place;`,
    prismaClient.$queryRaw<
      {
        averageRaisedPerPlayer: number;
        numPlayersWhoHaveRaised: number;
        goal: number;
        numPlayers: number;
      }[]
    >`select avg(if(coalesce(amount, 0) > 0, amount, null))                            as averageRaisedPerPlayer,
    SUM(if(coalesce(amount, 0) > 0, 1, 0)) as numPlayersWhoHaveRaised,
    sum(playerGoal)                        as goal,
    count(*)                               as numPlayers
from (select a.olliePlayerId as playerId, coalesce(sum(d.amount), 0) as amount, a.playerGoal
   from (select p.id as flexPlayerId, opm.ollie_player_id as olliePlayerId, p.goal_amount as playerGoal
         from players p,
              ollie_team_mapping otm,
              ollie_player_mapping opm
         where p.team_id = otm.flex_team_id
           and opm.flex_player_id = p.id
           and otm.ollie_org_fundraiser_id = ${p.orgFundraiserId}) a
            left join donations d on d.referred_by = a.flexPlayerId
   group by a.olliePlayerId, playerGoal
   order by coalesce(sum(d.amount), 0) desc) b;`,
    prismaClient.$queryRaw<FundraisingPrize[]>`
    select distinct(pz.id) as id,
   pz.name        as name,
   pz.amount      as amount,
   pz.description as description,
   pz.type        as type
from fundraisers f,
 fundraiser_prizes fp,
 prizes pz,
 (select flex_team_id from ollie_team_mapping where ollie_org_fundraiser_id = ${p.orgFundraiserId}) a
where fp.fundraiser_id = f.id
and pz.id = fp.prize_id
and (pz.type = 'player_ranking_on_org'
or pz.type = 'team_ranking_on_org')
and f.team_id = a.flex_team_id;`,
    prismaClient.ollie_org_fundraiser_mapping.findUnique({
      where: {
        id: p.orgFundraiserId
      }
    })
  ]);

  return {
    topPlayers,
    orgPrizes: adjustNonAmountFundraisingPrizeAmounts({ prizes: orgPrizes }),
    teamAmountsRaised,
    numPlayersWhoHaveRaised: playerData.length ? playerData[0].numPlayersWhoHaveRaised : 0,
    averageRaisedPerPlayer: playerData.length ? playerData[0].averageRaisedPerPlayer : 0,
    goal: orgFundraiserMapping?.org_fundraiser_goal ?? 0,
    numPlayers: playerData.length ? playerData[0].numPlayers : 0
  };
}

flex__server__getOrgFundraiserData.auth = () => {};
