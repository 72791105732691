import { TeamId, FundraisingPrize } from "@ollie-sports/models";
import moment from "moment";
import { getFlexPrismaClient } from "../../helpers";

export async function flex__server__deleteTeamPrize(p: { prizeId: number; fundraiserId: number }) {
  const prismaClient = getFlexPrismaClient();

  await prismaClient.fundraiser_prizes.deleteMany({
    where: {
      prize_id: p.prizeId,
      fundraiser_id: p.fundraiserId
    }
  });

  const remainingMappings = await prismaClient.fundraiser_prizes.findMany({
    where: {
      prize_id: p.prizeId
    }
  });

  if (!remainingMappings.length) {
    await prismaClient.prizes.delete({
      where: {
        id: p.prizeId
      }
    });
  }
}
flex__server__deleteTeamPrize.auth = () => {};
