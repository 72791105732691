import {
  FundraiserFinalPlayerRaffleTotalDetails,
  FundraisingOrgTopPlayer,
  FundraisingOrgTopTeam,
  FundraisingPrize,
  TeamId
} from "@ollie-sports/models";
import { calculatePlayerPrizeAndRaffleData, isRafflePrize } from "../../../compute/fundraising.compute";
import { getFlexPrismaClient, getServerHelpers } from "../../../helpers";
import _ from "lodash";
import { getPlayerFundraiserData } from "./getPlayerFundraiserData";
import { getTeamFundraiserData } from "./getTeamFundraiserData";

export async function getFinalRaffleTicketCountForTeam(p: {
  flexTeamId: number;
  teamId: TeamId;
  orgPrizes?: FundraisingPrize[];
  orgPlayers?: FundraisingOrgTopPlayer[];
  orgTeams?: FundraisingOrgTopTeam[];
  orgFundraiserId?: number;
}) {
  const result: FundraiserFinalPlayerRaffleTotalDetails[] = [];
  const prismaClient = getFlexPrismaClient();
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const { flexTeamId, teamId, orgPrizes, orgPlayers } = p;
  const [allPlayerMappings, teamFundraisingData, team] = await Promise.all([
    prismaClient.ollie_player_mapping.findMany({
      where: {
        players: {
          team_id: flexTeamId
        }
      }
    }),
    getTeamFundraiserData({
      flexTeamId,
      teamId
    }),
    h.Team.getDoc(teamId)
  ]);

  if (teamFundraisingData) {
    const teamPrizes = teamFundraisingData.teamPrizes;
    const teamPlayers = teamFundraisingData.playerAmountsRaised;

    const results = await Promise.all(
      allPlayerMappings.map(async playerMapping => {
        const playerId = playerMapping.ollie_player_id;
        if (playerId) {
          const [playerFundraisingData, player] = await Promise.all([
            getPlayerFundraiserData({
              flexTeamId,
              playerId,
              teamId
            }),
            h.Player.getDoc(playerId)
          ]);
          if (playerFundraisingData) {
            const allPrizes = teamFundraisingData.teamPrizes ?? [];
            const amountRaised = playerFundraisingData.donations.reduce((acc, val) => {
              acc += val.amount;
              return acc;
            }, 0);
            const playerPrizeData = calculatePlayerPrizeAndRaffleData({
              allPrizes,
              hasFundraiserEnded: true,
              numberOfViews: playerFundraisingData.pageViews,
              playerId,
              teamPlayers,
              orgPlayers,
              amountRaised,
              showReferralPrizes: !!p.orgFundraiserId && !!allPrizes.find(prize => isRafflePrize(prize)),
              numReferrals: playerFundraisingData?.referrals?.length ?? 0,
              teamId: p.teamId
            });
            return {
              numRaffleTicketsEarned: playerPrizeData.totalRaffleTicketsEarned,
              playerId,
              playerName: player ? player.virtualAthleteAccount.firstName + " " + player.virtualAthleteAccount.lastName : "",
              teamName: team?.name ?? ""
            };
          }
        }
        return null;
      })
    );
    return _.compact(results).filter(r => r.numRaffleTicketsEarned > 0);
  }
  return [];
}
