import { users } from "@prisma/client";
import { getFlexPrismaClient, bigIntToObject } from "../../helpers";

export async function flex__server__hello(p: {}) {
  const r1 = await getFlexPrismaClient().users.findMany();
  const r2: users[] = r1.map(i => {
    return bigIntToObject(i);
  });
  return r2;
}
flex__server__hello.auth = () => {};
