import { ORG_FUNDRAISER_VISIBILITY_STATUS, TeamFundraiserBasicData, TeamFundraisingData, TeamId } from "@ollie-sports/models";
import { fundraisers, ollie_team_mapping, users } from "@prisma/client";
import { getFlexPrismaClient, getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import moment from "moment";

export async function flex__server__getTeamFundraiser(p: {
  teamId: TeamId;
  flexTeamId: number;
}): Promise<TeamFundraiserBasicData | null> {
  const prismaClient = getFlexPrismaClient();

  const [teamMapping, fundraiser] = await Promise.all([
    prismaClient.ollie_team_mapping.findFirst({
      where: {
        flex_team_id: p.flexTeamId,
        ollie_team_id: p.teamId
      }
    }),
    prismaClient.fundraisers.findFirst({
      where: {
        team_id: p.flexTeamId
      }
    })
  ]);

  if (!teamMapping || !fundraiser) {
    return null;
  }

  return {
    flexTeamId: p.flexTeamId,
    fundraiserId: Number(fundraiser.id),
    fundraiserStatus: fundraiser.status as "active" | "inactive",
    fundraiserVisibilityStatus: teamMapping.fundraiser_visibility_status as ORG_FUNDRAISER_VISIBILITY_STATUS,
    fundraiserName: fundraiser.name,
    teamId: p.teamId,
    type: "team",
    endDateMS: moment(teamMapping.fundraiser_end_date).valueOf(),
    orgFundraiserId: teamMapping.ollie_org_fundraiser_id ? Number(teamMapping.ollie_org_fundraiser_id) : undefined,
    teamSponsorshipPlayerPercent: Number(teamMapping.team_sponsorship_player_percent ?? 0),
    fundsGoToTeam: teamMapping.funds_go_to_team
  };
}

flex__server__getTeamFundraiser.auth = () => {};
