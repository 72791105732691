import { AccountInfo, PlayerId, PrettyPlayer } from "@ollie-sports/models";
import { Prisma } from "@prisma/client";
import moment from "moment";
import { getFlexPrismaClient } from "../../../helpers";

export async function maybeAddFlexPlayerProfileImage(p: {
  playerInfo: { accountInfo: AccountInfo; playerId: PlayerId };
  flexPlayerId: number;
  flexUserId: number;
}) {
  const prismaClient = getFlexPrismaClient();
  const timestamp = moment().toDate();
  if (p.playerInfo.accountInfo.profileImageUri) {
    await prismaClient.files.create({
      data: {
        attachable_id: p.flexUserId,
        attachable_type: "App\\Models\\User",
        extension: "jpg",
        file_name: `${p.flexPlayerId}-${p.flexUserId}.jpg`,
        hash_name: `${p.flexPlayerId}-${p.flexUserId}.jpg`,
        is_main: 0,
        mime: "image\\jpg",
        optimized_url: p.playerInfo.accountInfo.profileImageUri,
        url: p.playerInfo.accountInfo.profileImageUri,
        thumbnail_url: p.playerInfo.accountInfo.profileImageUriSmall ?? p.playerInfo.accountInfo.profileImageUri,
        path: p.playerInfo.accountInfo.profileImageUri,
        size: 1,
        type: "profile_photo",
        user_id: Number(p.flexUserId),
        created_at: timestamp,
        updated_at: timestamp
      }
    });
  }
}
