import { AccountInfo, PlayerId, PrettyPlayer } from "@ollie-sports/models";
import { Prisma } from "@prisma/client";
import moment from "moment";
import { getFlexPrismaClient } from "../../../helpers";

export async function updateFlexProfileImage(p: {
  playerInfo: { accountInfo: AccountInfo; playerId: PlayerId };
  flexFileId: number;
}) {
  const prismaClient = getFlexPrismaClient();
  const timestamp = moment().toDate();
  if (p.playerInfo.accountInfo.profileImageUri) {
    await prismaClient.files.update({
      where: { id: p.flexFileId },
      data: {
        optimized_url: p.playerInfo.accountInfo.profileImageUri,
        url: p.playerInfo.accountInfo.profileImageUri,
        thumbnail_url: p.playerInfo.accountInfo.profileImageUriSmall ?? p.playerInfo.accountInfo.profileImageUri,
        path: p.playerInfo.accountInfo.profileImageUri,
        updated_at: timestamp
      }
    });
  }
}
