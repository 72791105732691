import { TeamId, FundraisingPrize } from "@ollie-sports/models";
import moment from "moment";
import { getFlexPrismaClient } from "../../helpers";

export async function flex__server__updateTeamPrize(p: { prizeId: number; prize: Omit<FundraisingPrize, "prizeId"> }) {
  const prismaClient = getFlexPrismaClient();
  const timestamp = moment().toDate();

  const newPrize = await prismaClient.prizes.update({
    where: {
      id: p.prizeId
    },
    data: {
      amount: p.prize.amount ?? 0,
      description: p.prize.description,
      name: p.prize.name,
      type: p.prize.type,
      is_custom: Boolean(p.prize.isCustom),
      updated_at: moment().toDate()
    }
  });
}
flex__server__updateTeamPrize.auth = () => {};
