import { OrgId, ORG_FUNDRAISER_VISIBILITY_STATUS, PlayerId, PrettyPlayer, Team, TeamId } from "@ollie-sports/models";
import moment from "moment";
import { getFlexPrismaClient, getUniversalHelpers } from "../../../helpers";
import { ObjectKeys } from "../../../utils";
import { Prisma } from "@prisma/client";

export async function linkOllieTeamToFlexTeam(p: {
  ollieTeam: Team;
  flexTeamId: number;
  orgId?: OrgId;
  prettyPlayers: PrettyPlayer[];
  fundraiserEndDate: number;
  fundraiserVisibilityStatus: ORG_FUNDRAISER_VISIBILITY_STATUS;
  ollieOrgFundraiserId?: number;
  fundsGoToTeam: boolean;
}) {
  const timestamp = moment().toDate();
  const prismaClient = getFlexPrismaClient();
  await prismaClient.ollie_team_mapping.create({
    data: {
      flex_team_id: p.flexTeamId,
      ollie_team_id: p.ollieTeam.id,
      ollie_org_id: p.orgId,
      created_at: timestamp,
      updated_at: timestamp,
      fundraiser_end_date: moment(p.fundraiserEndDate).toDate(),
      fundraiser_visibility_status: p.fundraiserVisibilityStatus,
      ollie_org_fundraiser_id: p.ollieOrgFundraiserId,
      funds_go_to_team: p.fundsGoToTeam
    }
  });
}
