import { TeamId, OrgId, ORG_FUNDRAISER_VISIBILITY_STATUS } from "@ollie-sports/models";
import { Prisma } from "@prisma/client";
import moment from "moment";
import { getUniversalHelpers, getFlexPrismaClient, bigIntToObject } from "../../helpers";
import { player__universal__fetchPlayersOnATeam } from "../player.api";
import { createFlexPlayersFromOlliePlayers } from "./helpers/createFlexPlayersFromOlliePlayers";
import { linkOllieTeamToFlexTeam } from "./helpers/linkOllieTeamToFlexTeam";

export async function flex__server__linkOllieTeamToFlexTeam(p: {
  ollieTeamId: TeamId;
  flexTeamId: number;
  endDateMS: number;
  fundraiserVisibilityStatus: ORG_FUNDRAISER_VISIBILITY_STATUS;
  fundsGoToTeam: boolean;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const prismaClient = getFlexPrismaClient();

  const [team, prettyPlayers] = await Promise.all([
    h.Team.getDoc(p.ollieTeamId),
    player__universal__fetchPlayersOnATeam({
      teamId: p.ollieTeamId
    })
  ]);

  if (!team) {
    throw new Error(`Cannot create flex team for teamId: ${p.ollieTeamId}`);
  }

  await linkOllieTeamToFlexTeam({
    flexTeamId: p.flexTeamId,
    ollieTeam: team,
    orgId: team?.orgId,
    prettyPlayers,
    fundraiserEndDate: p.endDateMS,
    fundraiserVisibilityStatus: p.fundraiserVisibilityStatus,
    fundsGoToTeam: p.fundsGoToTeam
  });

  const fundraiser = await prismaClient.fundraisers.findFirst({
    where: {
      team_id: p.flexTeamId
    }
  });

  await createFlexPlayersFromOlliePlayers({
    flexTeamId: p.flexTeamId,
    teamId: p.ollieTeamId,
    prettyPlayers,
    goalAmount: fundraiser?.individual_goal_amount ?? 0,
    orgId: team.orgId
  });
}
flex__server__linkOllieTeamToFlexTeam.auth = () => {};
