import {
  FlexDonation,
  FundraisingPrize,
  FundraisingReferral,
  OrgId,
  PlayerFundraisingData,
  PlayerId,
  PrettyPlayer,
  TeamId
} from "@ollie-sports/models";
import { bigIntToObject, getFlexPrismaClient, getUniversalHelpers } from "../../../helpers";
import _ from "lodash";
import { player__universal__fetchPlayersOnATeam } from "../../player.api";
import moment from "moment";
import { maybeRefreshFlexTeamFromOllieData } from "./maybeRefreshFlexTeamFromOllieData";
import { adjustNonAmountFundraisingPrizeAmounts } from "./adjustNonAmountFundraisingPrizeAmounts";

export async function getPlayerFundraiserData(p: {
  playerId: PlayerId;
  teamId: TeamId;
  flexTeamId: number;
}): Promise<PlayerFundraisingData | null> {
  const prismaClient = getFlexPrismaClient();

  let playerMapping = await prismaClient.ollie_player_mapping.findFirst({
    where: {
      ollie_player_id: p.playerId,
      players: {
        team_id: p.flexTeamId
      }
    }
  });

  // If there is no player mapping, then create one
  // TODO Flex is this a safe lazy create? It's going to be inside of a hook
  if (!playerMapping) {
    await maybeRefreshFlexTeamFromOllieData({
      ollieTeamId: p.teamId,
      flexTeamId: p.flexTeamId
    });
  }

  playerMapping = await prismaClient.ollie_player_mapping.findFirst({
    where: {
      ollie_player_id: p.playerId,
      players: {
        team_id: p.flexTeamId
      }
    }
  });

  const [teamMapping] = await Promise.all([
    prismaClient.ollie_team_mapping.findFirst({
      where: {
        ollie_team_id: p.teamId,
        flex_team_id: p.flexTeamId
      }
    })
  ]);

  if (!playerMapping || !teamMapping) {
    return null;
  }

  const [flexDonations, flexPlayer, numPlayersInOrgResults, playerRankInOrgResults, referrals] = await Promise.all([
    prismaClient.$queryRaw<
      {
        amount: number;
        donorName: string;
        product: string;
        message: string;
        date: Date;
      }[]
    >`select coalesce(b.donation_total, amount) as amount, donor_name as donorName, product_name as product, message, date
      from (select d.id         as donation_id,
                   d.amount     as amount,
                   p.invoice_id as invoice_id,
                   d.name       as donor_name,
                   p.created_at as date,
                   d.message    as message
            from donations d,
                 payments p
            where p.id = d.payment_id
              and d.referred_by = ${playerMapping.flex_player_id}) a
               left join (select it.invoice_id as invoice_id, pr.name as product_name, it.donation_total
                          from invoice_items it,
                               products pr
                          where it.product_id = pr.id) b on a.invoice_id = b.invoice_id;`,
    prismaClient.players.findFirst({
      where: {
        id: Number(playerMapping.flex_player_id)
      }
    }),
    teamMapping.ollie_org_fundraiser_id
      ? prismaClient.$queryRaw<{ numTeamsInOrg: number }[]>`select count(*) as numTeamsInOrg
      from players p,
           ollie_team_mapping otm
      where p.team_id = otm.flex_team_id
        and otm.ollie_org_fundraiser_id = ${teamMapping.ollie_org_fundraiser_id};`
      : undefined,
    teamMapping.ollie_org_fundraiser_id
      ? prismaClient.$queryRaw<{ place: number }[]>`select c.my_rank as place from
        (select RANK() OVER (
                ORDER BY amount desc
            ) my_rank,
               flexPlayerId
        from (select coalesce(sum(d.amount), 0) as amount, a.flexPlayerId
              from (select p.id as flexPlayerId
                    from players p,
                         ollie_team_mapping otm
                    where p.team_id = otm.flex_team_id
                      and otm.ollie_org_fundraiser_id = ${teamMapping.ollie_org_fundraiser_id}) a
                       left join donations d on d.referred_by = a.flexPlayerId
              group by a.flexPlayerId
              order by amount desc) b)c where c.flexPlayerId = ${playerMapping.flex_player_id};`
      : undefined,
    prismaClient.$queryRaw<
      FundraisingReferral[]
    >`select name, phone, entity_name as entityName, referred_by_player_id as flexPlayerId, id as referralId
      from referrals
      where referred_by_player_id = ${playerMapping.flex_player_id};`
  ]);

  const donations: FlexDonation[] = flexDonations.map(d => {
    if (d.product) {
      return {
        type: "product",
        date: moment(d.date).valueOf(),
        donorName: d.donorName,
        productName: d.product,
        message: d.message,
        amount: d.amount
      };
    } else {
      return {
        type: "cash",
        date: moment(d.date).valueOf(),
        donorName: d.donorName,
        message: d.message,
        amount: d.amount
      };
    }
  });

  const goal = flexPlayer?.goal_amount ?? 0;

  return {
    donations,
    goal,
    shareMessage: flexPlayer?.share_sms ?? "",
    numPlayersInOrg: numPlayersInOrgResults?.[0]?.numTeamsInOrg,
    playerRankInOrg: playerRankInOrgResults?.[0]?.place,
    pageViews: flexPlayer?.views_count ?? 0,
    playerUUID: flexPlayer?.uuid ?? "",
    flexPlayerId: Number(flexPlayer?.id ?? 0),
    referrals
  };
}
