import { TeamId, OrgId, PlayerId, PrettyPlayer } from "@ollie-sports/models";
import { Prisma } from "@prisma/client";
import moment from "moment";
import { getFlexPrismaClient, getUniversalHelpers } from "../../../helpers";
import { player__universal__fetchPlayersOnATeam } from "../../player.api";
import { createFlexPlayerFromOlliePrettyPlayer } from "./createFlexPlayer";
import { refreshFlexPlayer } from "./refreshFlexPlayer";

export async function maybeRefreshFlexTeamFromOllieData(p: { ollieTeamId: TeamId; flexTeamId: number }) {
  const prismaClient = getFlexPrismaClient();
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const timestamp = moment().toDate();

  const [ollieTeam, ollieFlexMapping, prettyPlayers] = await Promise.all([
    h.Team.getDoc(p.ollieTeamId),
    prismaClient.ollie_team_mapping.findFirst({
      where: {
        ollie_team_id: p.ollieTeamId,
        flex_team_id: p.flexTeamId
      }
    }),
    player__universal__fetchPlayersOnATeam({
      teamId: p.ollieTeamId
    })
  ]);

  if (!ollieTeam) {
    throw new Error(`Cannot find ollie team with team id: ${p.ollieTeamId}`);
  }

  const flexTeamId = ollieFlexMapping?.flex_team_id;

  if (!flexTeamId) {
    if (ollieTeam.orgId) {
      const org = await h.Org.getDoc(ollieTeam.orgId);
      // // TODO Flex
      // if (org && org.flexTemplateTeamId) {
      //   flex__server__createAndLinkFlexTeamToOllieTeamFromOrgTemplateTeam({
      //     flexTemplateTeamId: org.flexTemplateTeamId,
      //     ollieTeamId: p.ollieTeamId
      //   });
      // } else {
      //   return; // TODO Flex should we just return and do nothing because this means there is nothing to do if we hit this?
      // }
    } else {
      return; // TODO Flex should we just return and do nothing because this means there is nothing to do if we hit this?
    }
  } else {
    const [flexFundraiser, flexTeam] = await Promise.all([
      prismaClient.fundraisers.findFirst({
        where: {
          team_id: Number(flexTeamId)
        }
      }),
      prismaClient.teams.findFirst({
        where: {
          id: flexTeamId
        }
      })
    ]);
    // Check Team Name
    if (flexTeam && flexTeam.name !== ollieTeam.name) {
      await prismaClient.teams.update({
        where: { id: flexTeamId },
        data: { name: ollieTeam.name, updated_at: timestamp }
      });
    }

    // Check all players
    const [flexPlayers, flexPlayerMappings] = await Promise.all([
      prismaClient.players.findMany({
        where: {
          team_id: Number(flexTeamId)
        }
      }),
      prismaClient.ollie_player_mapping.findMany({
        where: {
          ollie_team_id: p.ollieTeamId
        }
      })
    ]);
    await Promise.all(
      prettyPlayers.map(pp => {
        const flexPlayerId = flexPlayerMappings.find(
          playerMapping => playerMapping.ollie_player_id === pp.player.id
        )?.flex_player_id;

        const flexPlayer = flexPlayerId ? flexPlayers.find(fp => flexPlayerId && flexPlayerId === fp.id) : undefined;
        if (flexPlayer) {
          // Make sure player is up to date
          if (
            flexPlayer.first_name !== pp.derived.accountInfo.firstName ||
            flexPlayer.last_name !== pp.derived.accountInfo.lastName ||
            (pp.derived.accountInfo.phoneNumber && flexPlayer.phone !== pp.derived.accountInfo.phoneNumber)
          ) {
            return refreshFlexPlayer({
              flexPlayerId: Number(flexPlayer.id),
              flexUserId: Number(flexPlayer.user_id),
              playerInfo: {
                accountInfo: pp.derived.accountInfo,
                playerId: pp.player.id
              }
            });
          }
        } else {
          // Create flex player

          return createFlexPlayerFromOlliePrettyPlayer({
            flexTeamId: Number(flexTeamId),
            prettyPlayer: pp,
            goalAmount: flexFundraiser?.individual_goal_amount,
            teamId: p.ollieTeamId,
            orgId: ollieTeam.orgId
          });
        }
        return null;
      })
    );
  }
}
