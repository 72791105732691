import { OrgId, PlayerId, PrettyPlayer, TeamId } from "@ollie-sports/models";
import moment from "moment";
import { getFlexPrismaClient, getUniversalHelpers } from "../../../helpers";
import { ObjectKeys } from "../../../utils";
import { v4 as uuid } from "uuid";
import { createFlexPlayerFromOlliePrettyPlayer } from "./createFlexPlayer";
import { Prisma } from "@prisma/client";

export async function createFlexPlayersFromOlliePlayers(p: {
  prettyPlayers: PrettyPlayer[];
  flexTeamId: number;
  goalAmount?: number;
  teamId: TeamId;
  orgId?: OrgId;
}) {
  await Promise.all(
    p.prettyPlayers.map(pp => {
      return createFlexPlayerFromOlliePrettyPlayer({
        flexTeamId: p.flexTeamId,
        prettyPlayer: pp,
        goalAmount: p.goalAmount,
        teamId: p.teamId,
        orgId: p.orgId
      });
    })
  );
}
