import { getFundsBreakdown } from "./helpers/getFundsBreakdown";
import _ from "lodash";
import { getFlexPrismaClient } from "../../helpers";

export async function flex__server__generateOrgFundsReport(p: {
  orgFundraiserId: number;
  ollieCommissionPercentages: {
    donations: number;
    teamSponsorship: number;
    clubSponsorship: number;
  };
}) {
  const prismaClient = getFlexPrismaClient();
  const flexTeamIds = _.compact(
    (
      await prismaClient.ollie_team_mapping.findMany({
        where: {
          ollie_org_fundraiser_id: p.orgFundraiserId
        },
        select: {
          flex_team_id: true
        }
      })
    ).map(a => Number(a.flex_team_id))
  );

  return await getFundsBreakdown({
    flexTeamIds,
    ollieCommissionPercentages: p.ollieCommissionPercentages
  });
}
flex__server__generateOrgFundsReport.auth = () => {};
