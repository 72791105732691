import { PlayerFundraisingData, PlayerId, TeamId } from "@ollie-sports/models";
import _ from "lodash";
import { getPlayerFundraiserData } from "./helpers/getPlayerFundraiserData";

export async function flex__server__getPlayerFundraiserData(p: {
  playerId: PlayerId;
  teamId: TeamId;
  flexTeamId: number;
}): Promise<PlayerFundraisingData | null> {
  return getPlayerFundraiserData(p);
}

flex__server__getPlayerFundraiserData.auth = () => {};
