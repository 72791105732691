import {
  FlexDonation,
  FundraisingPrize,
  FundraisingTeamTopPlayer,
  OrgId,
  PlayerId,
  TeamFundraisingData,
  TeamId
} from "@ollie-sports/models";
import { bigIntToObject, getFlexPrismaClient, getUniversalHelpers } from "../../../helpers";
import _ from "lodash";
import { player__universal__fetchPlayersOnATeam } from "../../player.api";
import { maybeRefreshFlexTeamFromOllieData } from "./maybeRefreshFlexTeamFromOllieData";
import { adjustNonAmountFundraisingPrizeAmounts } from "./adjustNonAmountFundraisingPrizeAmounts";
import moment from "moment";

export async function getTeamFundraiserData(p: { teamId: TeamId; flexTeamId: number }): Promise<TeamFundraisingData | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const prismaClient = getFlexPrismaClient();
  const prettyPlayers = await player__universal__fetchPlayersOnATeam({
    teamId: p.teamId
  });

  const playerMappings = await prismaClient.ollie_player_mapping.findMany({
    where: {
      ollie_team_id: p.teamId,
      players: {
        team_id: p.flexTeamId
      }
    }
  });

  if (!playerMappings || playerMappings.length !== prettyPlayers.length) {
    await maybeRefreshFlexTeamFromOllieData({
      ollieTeamId: p.teamId,
      flexTeamId: p.flexTeamId
    });
  }

  const flexMapping = await prismaClient.ollie_team_mapping.findFirst({
    where: {
      ollie_team_id: p.teamId,
      flex_team_id: p.flexTeamId
    }
  });

  const flexTeamId = flexMapping?.flex_team_id;

  if (!flexTeamId) {
    return null;
  }

  const [
    flexTeam,
    fundraiser,
    teamPrizes,
    teamRankInOrgResults,
    numTeamsInOrgResults,
    playerAmountsRaised,
    orgFundraiserImageUrlData,
    amountRaised,
    teamDonations
  ] = await Promise.all([
    prismaClient.teams.findUnique({
      where: { id: Number(flexTeamId) }
    }),
    prismaClient.fundraisers.findFirst({
      where: {
        team_id: Number(flexTeamId)
      }
    }),
    prismaClient.$queryRaw<
      FundraisingPrize[]
    >`select name, amount, description, a.type as type, url, a.id as prizeId, a.isCustom as isCustom from
    (select pz.name        as name,
        pz.amount      as amount,
        pz.description as description,
        pz.type        as type,
    pz.id as id,
    pz.is_custom as isCustom
    from fundraisers f,
      fundraiser_prizes fp,
      prizes pz
    where fp.fundraiser_id = f.id
    and pz.id = fp.prize_id
    and f.team_id = ${Number(flexTeamId)}) a left join files on
       attachable_type = 'App\\\\Models\\\\Prize' and attachable_id = a.id;`,
    flexMapping.ollie_org_fundraiser_id
      ? prismaClient.$queryRaw<{ place: number }[]>`select c.my_rank as place
from (select RANK() OVER (
    ORDER BY amount desc
    ) my_rank,
             flexTeamId
      from (select coalesce(sum(d.amount), 0) as amount, a.flexTeamId
            from (select otm.flex_team_id as flexTeamId,
                         p.id             as flexPlayerId
                  from ollie_team_mapping otm
                           left join players p
                                     on p.team_id = otm.flex_team_id
                                         and otm.ollie_org_fundraiser_id = ${flexMapping.ollie_org_fundraiser_id}) a
                     left join donations d on d.referred_by = a.flexPlayerId
            group by a.flexTeamId
            order by amount desc) b) c
where c.flexTeamId = ${flexTeamId};`
      : null,
    flexMapping.ollie_org_fundraiser_id
      ? prismaClient.$queryRaw<
          {
            numTeamsInOrg: number;
          }[]
        >`select count(*) as numTeamsInOrg
        from ollie_team_mapping
        where ollie_org_fundraiser_id = ${flexMapping.ollie_org_fundraiser_id};`
      : null,
    prismaClient.$queryRaw<
      FundraisingTeamTopPlayer[]
    >`select c.playerId, c.amount, c.place, count(distinct if(pv.id is not null, pv.id, null)) as pageViews from
    (select b.playerId, b.amount, rank() over (order by amount desc) as place, b.flexPlayerId as flexPlayerId
        from (select a.olliePlayerId as playerId, coalesce(sum(d.amount), 0) as amount, a.flexPlayerId as flexPlayerId
              from (select p.id as flexPlayerId, opm.ollie_player_id as olliePlayerId
                    from players p,
                         ollie_team_mapping otm,
                         ollie_player_mapping opm
                    where opm.flex_player_id = p.id
                      and otm.flex_team_id = p.team_id
                      and otm.flex_team_id = ${p.flexTeamId}
                    group by p.id, opm.ollie_player_id) a
                       left join donations d on d.referred_by = a.flexPlayerId
              group by a.flexPlayerId, a.olliePlayerId
              order by coalesce(sum(d.amount), 0) desc) b) c
            left join page_views pv on pv.viewable_id = c.flexPlayerId and viewable_type ='App\\\\Models\\\\Player'
    group by c.playerId, c.amount, c.place;`,
    flexMapping.ollie_org_fundraiser_id
      ? prismaClient.ollie_org_fundraiser_mapping.findUnique({
          where: {
            id: Number(flexMapping.ollie_org_fundraiser_id)
          },
          select: {
            org_fundraiser_image_url: true
          }
        })
      : null,
    prismaClient.$queryRaw<{ amount: number }[]>`select sum(d.amount) as amount
    from fundraisers f,
         donations d
    where f.id = d.fundraiser_id
      and f.team_id = ${p.flexTeamId};`,
    prismaClient.$queryRaw<
      {
        amount: number;
        donorName: string;
        product: string;
        message: string;
        date: Date;
      }[]
    >`select coalesce(b.donation_total, amount) as amount, donor_name as donorName, product_name as product, message, date
    from (select d.id         as donation_id,
                 d.amount     as amount,
                 p.invoice_id as invoice_id,
                 d.name       as donor_name,
                 p.created_at as date,
                 d.message    as message
          from donations d,
               fundraisers f,
               payments p
          where p.id = d.payment_id
            and f.id = d.fundraiser_id
            and f.team_id = ${p.flexTeamId}) a
             left join (select it.invoice_id as invoice_id, pr.name as product_name, it.donation_total
                        from invoice_items it,
                             products pr
                        where it.product_id = pr.id) b on a.invoice_id = b.invoice_id;`
  ]);

  const donations: FlexDonation[] = teamDonations.map(d => {
    if (d.product) {
      return {
        type: "product",
        date: moment(d.date).valueOf(),
        donorName: d.donorName,
        productName: d.product,
        message: d.message,
        amount: d.amount
      };
    } else {
      return {
        type: "cash",
        date: moment(d.date).valueOf(),
        donorName: d.donorName,
        message: d.message,
        amount: d.amount
      };
    }
  });

  if (!flexTeam || !fundraiser) {
    return null;
  }

  return {
    playerAmountsRaised,
    goal: fundraiser.goal_amount,
    teamPrizes: adjustNonAmountFundraisingPrizeAmounts({ prizes: teamPrizes }),
    teamRankInOrg: teamRankInOrgResults?.[0]?.place,
    numTeamsInOrg: numTeamsInOrgResults?.[0]?.numTeamsInOrg,
    flexFundraiserSlug: fundraiser.slug ?? "",
    orgFundraiserImageUrl: orgFundraiserImageUrlData?.org_fundraiser_image_url
      ? orgFundraiserImageUrlData.org_fundraiser_image_url
      : undefined,
    amountRaised: amountRaised?.[0]?.amount ?? 0,
    donations
  };
}
