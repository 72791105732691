import { getFundsBreakdown } from "./helpers/getFundsBreakdown";
import _ from "lodash";
import { getFlexPrismaClient } from "../../helpers";

export async function flex__server__generateTeamFundsReport(p: {
  flexTeamId: number;
  ollieCommissionPercentages: {
    donations: number;
    teamSponsorship: number;
    clubSponsorship: number;
  };
}) {
  return await getFundsBreakdown({
    flexTeamIds: [p.flexTeamId],
    ollieCommissionPercentages: p.ollieCommissionPercentages
  });
}
flex__server__generateTeamFundsReport.auth = () => {};
