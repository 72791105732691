import { PrettyPlayer, Team } from "@ollie-sports/models";
import moment from "moment";
import { getFlexPrismaClient } from "../../../helpers";
import { v4 as uuid } from "uuid";
import { player__universal__fetchPlayersOnATeam } from "../../player.api";
import { Prisma } from "@prisma/client";
export async function cloneFlexTeam(p: { flexTeamId: number; ollieTeam: Team; prettyPlayers: PrettyPlayer[] }) {
  const prismaClient = getFlexPrismaClient();
  const [flexTeam, teamFiles, fundraiser] = await Promise.all([
    prismaClient.teams.findUnique({
      where: {
        id: p.flexTeamId
      }
    }),
    prismaClient.files.findMany({
      where: {
        attachable_type: "App\\Models\\Team",
        attachable_id: p.flexTeamId
      }
    }),
    prismaClient.fundraisers.findFirst({
      where: {
        team_id: Number(p.flexTeamId)
      }
    })
  ]);

  if (!flexTeam) {
    throw new Error(`Could not find matching flex team for flex id: ${p.flexTeamId}`);
  }

  if (!fundraiser) {
    throw new Error(`Could not find a fundraiser for flex team id: ${p.flexTeamId}`);
  }

  const timestamp = moment().toDate();

  // Create team user and team
  const [teamUser, newTeam] = await Promise.all([
    prismaClient.users.create({
      data: {
        email: `${p.ollieTeam.id.replace("-", "")}@${moment().valueOf()}.com`,
        first_name: "Team",
        last_name: "Admin",
        password: uuid(),
        type: "team_supervisor",
        uuid: uuid(),
        created_at: timestamp,
        updated_at: timestamp
      }
    }),
    prismaClient.teams.create({
      data: {
        ...flexTeam,
        id: undefined,
        name: p.ollieTeam.name,
        uuid: uuid(),
        slug: uuid(),
        created_at: timestamp,
        updated_at: timestamp
      }
    })
  ]);

  // create team user link (this is the team admin), ollie mapping, and fundraiser
  const [teamUserMapping, newFundraiser] = await Promise.all([
    prismaClient.team_users.create({
      data: {
        team_id: Number(newTeam.id),
        user_id: Number(teamUser.id),
        created_at: timestamp,
        updated_at: timestamp
      }
    }),
    prismaClient.fundraisers.create({
      data: {
        ...fundraiser,
        id: undefined,
        team_id: Number(newTeam.id),
        total_donations: 0,
        commission_percentage: 0,
        custom_commission: 0,
        created_at: timestamp,
        updated_at: timestamp,
        slug: uuid(),
        goal_amount: fundraiser.individual_goal_amount * p.prettyPlayers.length // TODO Flex: Is this what we wanna do? Figure out goal strategy
        // TODO Flex: What else to override here?
      }
    })
  ]);

  // get fundraiser files, products, categories, prizes
  const [fundraisingFiles, fundraiserProducts, fundraiserCategories, fundraiserPrizes] = await Promise.all([
    prismaClient.files.findMany({
      where: {
        attachable_type: "App\\Models\\Fundraiser",
        attachable_id: fundraiser.id
      }
    }),
    prismaClient.fundraiser_products.findMany({
      where: {
        fundraiser_id: Number(fundraiser.id)
      }
    }),
    prismaClient.fundraiser_categories.findMany({
      where: {
        fundraiser_id: Number(fundraiser.id)
      }
    }),
    prismaClient.fundraiser_prizes.findMany({
      where: {
        fundraiser_id: Number(fundraiser.id)
      }
    })
  ]);

  // duplicate team files, fundraiser files, products, categories, prizes
  const promises: Promise<any>[] = [];

  teamFiles.forEach(f => {
    promises.push(
      prismaClient.files.create({
        data: {
          ...f,
          attachable_id: newTeam.id,
          id: undefined,
          created_at: timestamp,
          updated_at: timestamp
        }
      })
    );
  });
  fundraisingFiles.forEach(f => {
    promises.push(
      prismaClient.files.create({
        data: {
          ...f,
          attachable_id: newFundraiser.id,
          id: undefined,
          created_at: timestamp,
          updated_at: timestamp
        }
      })
    );
  });
  fundraiserProducts.forEach(fp => {
    promises.push(
      prismaClient.fundraiser_products.create({
        data: {
          ...fp,
          id: undefined,
          fundraiser_id: Number(newFundraiser.id),
          created_at: timestamp,
          updated_at: timestamp
        }
      })
    );
  });
  fundraiserCategories.forEach(fc => {
    promises.push(
      prismaClient.fundraiser_categories.create({
        data: {
          ...fc,
          id: undefined,
          fundraiser_id: Number(newFundraiser.id),
          created_at: timestamp,
          updated_at: timestamp
        }
      })
    );
  });
  fundraiserPrizes.forEach(fp => {
    promises.push(
      prismaClient.fundraiser_prizes.create({
        data: {
          ...fp,
          id: undefined,
          fundraiser_id: Number(newFundraiser.id),
          created_at: timestamp,
          updated_at: timestamp
        }
      })
    );
  });

  await Promise.all(promises);

  return { newTeam, newFundraiser };
}
