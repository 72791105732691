import { getFlexPrismaClient, getServerHelpers, getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import moment from "moment";
import { Request } from "express";
import { AllValues, formatMoneyCentsToDollarCentPrettyString, isProduction, ObjectKeys } from "../../utils";
import { Prisma } from "@prisma/client";
import {
  AccountInfo,
  LowPriorityNotificationDetailType,
  NotificationType,
  Org,
  PlayerBundle,
  PlayerBundle__AccountType,
  PlayerId,
  PushNotificationSettingToRespect,
  Team,
  Team__Account
} from "@ollie-sports/models";
import {
  EmailDataNormal,
  generateAndProcessNotifications,
  NotificationGeneratorInfo__LowPriority
} from "../notification/notification.plumbing";
import { translateServer } from "@ollie-sports/i18n";
import { getFundraisingPlayerPageLink, getFundraisingTeamPageLink } from "../../compute/fundraising.compute";

type ReportType = "emailDay" | "textDay" | "socialMediaDay" | "generic" | "finalDay";
type FinalNotificationInfo = {
  selfAthlete?: {
    teamId: string;
    fundraiserId: bigint;
    playerId: string;
    accountInfo: AccountInfo;
    totalDonationCents: number;
  }[];
  guardian?: { teamId: string; fundraiserId: bigint; playerId: string; accountInfo: AccountInfo; totalDonationCents: number }[];
  staff?: { teamId: string; fundraiserId: bigint; totalDonationCents: number }[];
  adminOrgs?: { org: Org; totalDonationCents: number }[];
};

export async function flex__server__sendProgressReports() {
  // SERVER_ONLY_TOGGLE

  const { getAppPgPool, appOllieFirestoreV2: h, serverConfig } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  const prismaClient = getFlexPrismaClient();
  const teamsWithActiveFundraisers = await prismaClient.$queryRaw<
    {
      ollie_team_id: string;
      ollie_org_id: string | null;
      flex_team_id: bigint;
      flex_fundraiser_id: bigint;
      fundraiser_end_date: string;
    }[]
  >`
    select otm.ollie_team_id ollie_team_id,
        otm.ollie_org_id ollie_org_id,
        f.id flex_fundraiser_id,
        t.id flex_team_id,
        otm.fundraiser_end_date fundraiser_end_date
    from ollie_team_mapping otm,
        fundraisers f,
        teams t
    where t.id = otm.flex_team_id
      and f.team_id = t.id
      and f.status = 'active'
      and otm.fundraiser_visibility_status = 'all'
  `;

  //E.g. 1, 7, 14, 21, 28, etc
  const progressReportTimes = [1, ...[...Array(6).keys()].map(a => (a + 1) * 7)].map(daysBeforeEnd => ({
    daysBeforeEnd,
    //Subtract 14 hours to get the send minute which is approximately 10 am local time (since fundraisers end at midnight in local time)
    minutesBeforeEnd: daysBeforeEnd * 24 * 60 + 14 * 60
  }));

  const teamsToSendReportsTo = _(
    await Promise.all(
      teamsWithActiveFundraisers
        .map(teamInfo => {
          const minutesUntilTeamFundraiserWillEnd = Math.round(
            (new Date(teamInfo.fundraiser_end_date).valueOf() - Date.now()) / (1000 * 60)
          );
          const range = progressReportTimes.find(a => {
            return (
              minutesUntilTeamFundraiserWillEnd > a.minutesBeforeEnd - 10 &&
              minutesUntilTeamFundraiserWillEnd < a.minutesBeforeEnd + 10
            );
          });

          return { ...teamInfo, daysBeforeEnd: range?.daysBeforeEnd as number };
        })
        .filter(a => typeof a.daysBeforeEnd === "number")
        .map(async a => {
          const reportToSend: ReportType = await getReportToSend(a);

          return { ...a, reportToSend };
        })
    )
  )
    .groupBy(a => a.ollie_team_id)
    .mapValues(a => a[0])
    .value();

  const teamIds = Object.keys(teamsToSendReportsTo);

  if (!teamIds.length) {
    return;
  }

  const staffThatNeedReports = await getAppPgPool()
    .query(
      ` select t.item as team, key as account_id, *
        from mirror_team t,
            jsonb_each(t.item -> 'accounts') a
        where t.id = ANY($1)  and a.value -> 'roles' ->> 'staff' = 'true'`,
      [`{${teamIds.join(",")}}`]
    )
    .then(a => a.rows as { account_id: string; team: Team }[]);

  const players = await prismaClient.ollie_player_mapping.findMany({
    where: {
      ollie_team_id: {
        in: teamIds
      }
    }
  });

  const [olliePlayerIdToFlexUUID, ollieTeamIdToFlexTeamInfo] = await Promise.all([
    prismaClient.players
      .findMany({
        select: {
          uuid: true,
          id: true
        },
        where: {
          id: {
            in: players.filter(a => !!a.flex_player_id).map(a => a.flex_player_id as bigint)
          }
        }
      })
      .then(a => {
        const map: Record<string, string> = {};
        a.forEach(b => {
          const olliePlayerId = players.find(c => c.flex_player_id === b.id)!.ollie_player_id;
          if (olliePlayerId) {
            map[olliePlayerId] = b.uuid;
          }
        });
        return map;
      }),
    prismaClient.teams
      .findMany({
        select: {
          slug: true,
          id: true
        },
        where: {
          id: {
            in: Object.keys(teamsToSendReportsTo).map(a => teamsToSendReportsTo[a].flex_team_id)
          }
        }
      })
      .then(flexTeams => {
        const map: Record<string, { slug: string | null; id: number }> = {};
        flexTeams.forEach(flexTeam => {
          const toSendTeam = Object.values(teamsToSendReportsTo).find(c => Number(c.flex_team_id) === Number(flexTeam.id));

          if (toSendTeam?.ollie_team_id) {
            map[toSendTeam.ollie_team_id] = {
              id: Number(flexTeam.id),
              slug: flexTeam.slug
            };
          }
        });
        return map;
      })
  ]);

  const playersAndGuardiansThatNeedReports = await getAppPgPool()
    .query(
      `select player_id,
              c.key                                    as account_id,
              team_id,
              player_bundle -> 'virtualAthleteAccount' as player_info,
              c.value ->> 'type'                       as type
        from (
                select *, p.item ->> 'teamId' as team_id
                from (
                          select b.key as player_id, pb.item as player_bundle
                          from mirror_playerbundle pb,
                              jsonb_each(pb.item -> 'derived' -> 'linkedPlayers') as b
                          where b.key = ANY($1)
                      ) aa,
                      mirror_player p
                where p.id = player_id
            ) bb,
            jsonb_each(player_bundle -> 'managingAccounts') c`,
      [`{${players.map(a => a.ollie_player_id).join(",")}}`]
    )
    .then(
      a =>
        a.rows as {
          player_id: string;
          account_id: string;
          team_id: string;
          player_info: AccountInfo;
          type: PlayerBundle__AccountType;
        }[]
    );

  const orgsThatNeedReports = await getAppPgPool()
    .query(
      `
      select distinct o.item org
      from mirror_org o,
          mirror_team t
      where t.item ->> 'orgId' = o.id
        and t.id = ANY($1)
  `,
      [`{${teamIds.join(",")}}`]
    )
    .then(a => a.rows.map(b => b.org) as Org[]);

  const orgAdminsThatNeedReports = _(orgsThatNeedReports)
    .uniqBy(a => a.id)
    .map(o => Object.keys(o?.accounts ?? {}).map(a => ({ org: o, accountId: a })))
    .flatten()
    .value();

  const playerDonationSums = await prismaClient.$queryRaw<
    { olliePlayerId: string; ollieOrgId: string; ollieTeamId: string; amount: number }[]
  >`
    select *
    from (select a.olliePlayerId, a.ollieTeamId, a.ollieOrgId, coalesce(sum(d.amount), 0) as amount
          from (select p.id as flexPlayerId, otm.ollie_team_id as ollieTeamId, otm.ollie_org_id ollieOrgId, opm.ollie_player_id as olliePlayerId
                from players p,
                    ollie_team_mapping otm,
                    ollie_player_mapping opm,
                    fundraisers f
                where p.team_id = otm.flex_team_id
                  and opm.flex_player_id = p.id
                  and f.team_id = otm.flex_team_id
                  and f.status = 'active'
                  and otm.ollie_team_id in (${Prisma.join(teamIds, ",")})) a
                  left join donations d on d.referred_by = a.flexPlayerId
          group by a.olliePlayerId, a.ollieTeamId, a.ollieOrgId
          order by coalesce(sum(d.amount), 0)) b
    where b.amount > 0`;

  const donationsByPlayerId = _(playerDonationSums)
    .groupBy(a => a.olliePlayerId)
    .mapValues(a => a[0].amount) //Only ever 1 playerId in the array...
    .value();

  const donationsByTeamId = _(playerDonationSums)
    .groupBy(a => a.ollieTeamId)
    .mapValues(a => _.sumBy(a, b => b.amount))
    .value();

  const donationsByOrgId = _(playerDonationSums)
    .groupBy(a => a.ollieOrgId)
    .mapValues(a => _.sumBy(a, b => b.amount))
    .value();

  const finalNotificationInfo: Record<string, FinalNotificationInfo> = {};

  orgAdminsThatNeedReports.map(a => {
    finalNotificationInfo[a.accountId] = finalNotificationInfo[a.accountId] || {};
    finalNotificationInfo[a.accountId].adminOrgs = finalNotificationInfo[a.accountId].adminOrgs || [];
    finalNotificationInfo[a.accountId].adminOrgs!.push({
      org: a.org,
      totalDonationCents: donationsByOrgId[a.org.id] ?? 0
    });
  });

  staffThatNeedReports.map(a => {
    finalNotificationInfo[a.account_id] = finalNotificationInfo[a.account_id] || {};
    finalNotificationInfo[a.account_id].staff = (finalNotificationInfo[a.account_id].staff as any) || [];
    finalNotificationInfo[a.account_id].staff!.push({
      teamId: a.team.id,
      fundraiserId: teamsToSendReportsTo[a.team.id].flex_fundraiser_id,
      totalDonationCents: donationsByTeamId[a.team.id] ?? 0
    });
  });

  playersAndGuardiansThatNeedReports.map(a => {
    finalNotificationInfo[a.account_id] = finalNotificationInfo[a.account_id] || {};
    finalNotificationInfo[a.account_id][a.type] = (finalNotificationInfo[a.account_id][a.type] as any) || [];
    finalNotificationInfo[a.account_id][a.type]!.push({
      accountInfo: a.player_info,
      teamId: a.team_id,
      playerId: a.player_id,
      fundraiserId: teamsToSendReportsTo[a.team_id].flex_fundraiser_id,
      totalDonationCents: donationsByPlayerId[a.player_id] ?? 0
    });
  });

  function getShareLinks(info: PushNotificationInfo & { defaultTeamId?: string; defaultFlexTeamId?: number }): {
    routerPath: string;
    shareUrl?: string;
  } {
    const uniqueTeamInfo =
      info.uniquelyRelevantTeamId && ollieTeamIdToFlexTeamInfo[info.uniquelyRelevantTeamId]?.slug
        ? {
            ollie: info.uniquelyRelevantTeamId,
            flex: ollieTeamIdToFlexTeamInfo[info.uniquelyRelevantTeamId]
          }
        : null;

    let routerPath: string;
    if (info.routerOpenAction === "generic-share" || info.routerOpenAction === "sms-share") {
      if (uniqueTeamInfo) {
        routerPath = `main/money/fundraisingTeamHomePage/${uniqueTeamInfo.ollie}/${uniqueTeamInfo.flex.id}/${info.routerOpenAction}`;
      } else if (info.defaultTeamId && info.defaultFlexTeamId) {
        routerPath = `main/money/fundraisingTeamHomePage/${info.defaultTeamId}/${info.defaultFlexTeamId}/${info.routerOpenAction}`;
      } else {
        routerPath = `main/money/fundraisingList/none`;
      }
    } else {
      if (uniqueTeamInfo) {
        routerPath = `main/money/fundraisingTeamHomePage/${uniqueTeamInfo.ollie}/${uniqueTeamInfo.flex.id}/${info.routerOpenAction}`;
      } else {
        routerPath = `main/money/fundraisingList/${info.routerOpenAction}`;
      }
    }

    let shareUrl: undefined | string;
    if (info.uniquelyRelevantPlayerId && olliePlayerIdToFlexUUID[info.uniquelyRelevantPlayerId]) {
      shareUrl = getFundraisingPlayerPageLink({
        flexAppUrl: serverConfig.flexAppUrl,
        flexUUID: olliePlayerIdToFlexUUID[info.uniquelyRelevantPlayerId]
      });
    } else if (uniqueTeamInfo && uniqueTeamInfo.flex.slug) {
      shareUrl = getFundraisingTeamPageLink({
        flexAppUrl: serverConfig.flexAppUrl,
        flexSlug: uniqueTeamInfo.flex.slug
      });
    }

    return { routerPath, shareUrl };
  }

  await generateAndProcessNotifications({
    data: _(finalNotificationInfo)
      .omitBy(a => !a.adminOrgs?.length)
      .toPairs()
      .map(([accountId, info]) => ({ accountId, info: info! }))
      .value(),
    generateFn: async a => {
      const serverLocale = a.accountPrivate.communicationLocale;
      const {
        org: { name, shortName, id },
        totalDonationCents
      } = a.data.info.adminOrgs![0];

      const dollarStr = formatMoneyCentsToDollarCentPrettyString(totalDonationCents, true);

      const account = await h.Account.getDoc(a.accountPrivate.id);
      if (!account) {
        throw new Error("Notification sent to user without account?!?");
      }

      const message = translateServer(
        {
          defaultMessage: `Your club {name} has raised {dollarStr}. Nice work! Only {daysLeft} days left - send a club chat message to encourage everyone!`,
          serverLocale
        },
        {
          name,
          dollarStr,
          daysLeft: Object.values(teamsToSendReportsTo).find(t => t.ollie_org_id === id)?.daysBeforeEnd ?? "?"
        }
      );

      return getNotificationGeneratorInfo({
        idempotencyKey: "admin-progress-report-" + moment().format("YYYY-MM-DD") + id,
        accountEmail: account.email,
        expireAtMS: moment().add(6, "days").valueOf(),
        title: translateServer({ defaultMessage: `{shortName} Fundraiser Progress`, serverLocale }, { shortName }),
        message,
        emailMessage: message,
        routerPath: "main/money/fundraisingList/none"
      });
    }
  });

  await generateAndProcessNotifications({
    data: Object.keys(finalNotificationInfo).map(a => ({ accountId: a, info: finalNotificationInfo[a] })),
    generateFn: async a => {
      const serverLocale = a.accountPrivate.communicationLocale;

      const info = a.data.info;
      const { adminOrgs, guardian, selfAthlete, staff } = a.data.info;
      const thisUserRelevantTeamIds = _([
        guardian?.map(b => b.teamId),
        selfAthlete?.map(b => b.teamId),
        staff?.map(b => b.teamId)
      ])
        .compact()
        .flatten()
        .uniq()
        .value();

      if (!adminOrgs?.length && !thisUserRelevantTeamIds.length) {
        return null;
      }

      const reportToSendArr = _(thisUserRelevantTeamIds)
        .map(b => teamsToSendReportsTo[b].reportToSend)
        .compact()
        .uniq()
        .value();

      const daysBeforeEndArr = _(teamIds)
        .map(b => teamsToSendReportsTo[b].daysBeforeEnd)
        .compact()
        .uniq()
        .value();

      const daysBeforeEnd = daysBeforeEndArr.length === 1 ? daysBeforeEndArr[0] : null;

      const reportToSend: ReportType = reportToSendArr.length === 1 ? reportToSendArr[0] : "generic";

      const messageInfo = getPushNotificationInfo({ reportToSend, serverLocale, daysBeforeEnd, ...info });

      if (!messageInfo) {
        return null;
      }

      const expireAtMS = moment().add(6, "days").valueOf();
      const { routerPath, shareUrl } = getShareLinks({
        ...messageInfo,
        defaultTeamId: thisUserRelevantTeamIds[0],
        defaultFlexTeamId: ollieTeamIdToFlexTeamInfo[thisUserRelevantTeamIds[0]].id
      });

      const account = await h.Account.getDoc(a.accountPrivate.id);

      if (!account) {
        throw new Error("Somehow got user without account when sending fundraiser report...");
      }

      return getNotificationGeneratorInfo({
        idempotencyKey:
          "progress-report-" +
          moment().format("YYYY-MM-DD") +
          [...(info.adminOrgs?.map(b => b.org.id) || []), ...thisUserRelevantTeamIds].join("|"),
        message: messageInfo.message,
        title: messageInfo.title,
        expireAtMS,
        emailMessage: messageInfo.emailMessage,
        accountEmail: account.email,
        routerPath,
        shareUrl
      });
    }
  });

  await Promise.all(
    Object.keys(teamsToSendReportsTo).map(async tid => {
      await addNewlySentReport({
        ollie_team_id: tid,
        flex_fundraiser_id: teamsToSendReportsTo[tid].flex_fundraiser_id,
        report: teamsToSendReportsTo[tid].reportToSend
      }).catch(e => {
        olliePipe.emitEvent({ type: "error-problem-saving-fundraiser-progress-report-send", payload: e });
      });
    })
  );

  // SERVER_ONLY_TOGGLE
}
flex__server__sendProgressReports.auth = (req: Request) => {
  //Magic token must be included in all requests. Not too important to be super secure since presumably this is a short term endpoint
  if (req.get("x-magic-token") !== "LY:82vV$~uhk<N^") {
    throw new Error("Need magic token!");
  }
};

type ReportCacheDoc = { expiresAtMS: number; sent: { [k in ReportType]?: true } };

function getProgressReportRTDBRef(p: { ollie_team_id: string; flex_fundraiser_id: bigint }) {
  const { appOllieRtdb } = getServerHelpers();
  return appOllieRtdb._RawRtdb.ref(["fundraiserProgress", p.ollie_team_id, p.flex_fundraiser_id].join("/"));
}

async function addNewlySentReport(p: { ollie_team_id: string; flex_fundraiser_id: bigint; report: ReportType }) {
  const expiresAtMS = Date.now() + 7.776e9; //90 days
  const ref = getProgressReportRTDBRef(p);
  if (p.report === "generic" || p.report === "finalDay") {
    //No need to keep a record of when we sent finalDay or generic reports since generic is catchall and finalDay is always the day before
    return;
  }

  await ref.child("expiresAtMS").set(expiresAtMS);
  await ref.child(["sent", p.report].join("/")).set(true);
}

async function getReportToSend(p: {
  ollie_team_id: string;
  flex_fundraiser_id: bigint;
  daysBeforeEnd: number;
}): Promise<ReportType> {
  if (p.daysBeforeEnd === 1) {
    return "finalDay";
  }

  const snap = await getProgressReportRTDBRef(p).once("value");
  const val: undefined | ReportCacheDoc = snap.val();

  if (!val?.sent?.socialMediaDay || val.expiresAtMS < Date.now()) {
    return "socialMediaDay";
  }

  if (!val.sent.textDay) {
    return "textDay";
  }

  if (!val.sent.socialMediaDay) {
    return "emailDay";
  }

  return "generic";
}

type PushNotificationInfo = {
  title: string;
  message: string;
  emailMessage: string;
  routerOpenAction: "send-reminder" | "generic-share" | "sms-share";
  //Uniquely relevant team/player id is only set if there's exactly one team/player of interest to the user. Used for generating the share links
  uniquelyRelevantTeamId?: string;
  uniquelyRelevantPlayerId?: string;
};
function getPushNotificationInfo(
  p: FinalNotificationInfo & {
    serverLocale: string;
    reportToSend: ReportType;
    daysBeforeEnd: number | null;
  }
): PushNotificationInfo | null {
  let messageInfo: PushNotificationInfo | null = null;
  const { serverLocale } = p;

  const genericMessageCTA = translateServer({ defaultMessage: "Tap this notification to share.", serverLocale });

  if (p.staff?.length) {
    const uniquelyRelevantTeamId = p.staff.length === 1 ? p.staff[0].teamId : undefined;

    const dollarStr = formatMoneyCentsToDollarCentPrettyString(
      p.staff.reduce((a, b) => a + b.totalDonationCents, 0),
      true
    );
    const hasMultipleTeams = p.staff.length > 1;

    const messageCTA = hasMultipleTeams
      ? translateServer(
          { defaultMessage: "Your players have raised {dollarStr}. Tap this notification to send a reminder.", serverLocale },
          { dollarStr }
        )
      : translateServer(
          { defaultMessage: "You've raised {dollarStr}. Tap this notification to send a reminder.", serverLocale },
          { dollarStr }
        );

    if (p.reportToSend === "emailDay") {
      const message = translateServer(
        {
          defaultMessage: "Remind your {numTeams, plural, one{team} other{teams} } to email at least 10 people today!",
          serverLocale
        },
        { numTeams: p.staff.length }
      );
      messageInfo = {
        routerOpenAction: "send-reminder",
        uniquelyRelevantTeamId,
        title: translateServer({ defaultMessage: "Fundraiser: Email Day", serverLocale }),
        emailMessage: message,
        message: [message, messageCTA].join(" ")
      };
    } else if (p.reportToSend === "textDay") {
      const message = translateServer(
        {
          defaultMessage: "Remind your {numTeams, plural, one{team} other{teams} } to text at least 10 people today!",
          serverLocale
        },
        { numTeams: p.staff.length }
      );
      messageInfo = {
        uniquelyRelevantTeamId,
        routerOpenAction: "send-reminder",
        title: translateServer({ defaultMessage: "Fundraiser: Text Day", serverLocale }),
        emailMessage: message,
        message: [message, messageCTA].join(" ")
      };
    } else if (p.reportToSend === "socialMediaDay") {
      const message = translateServer(
        {
          defaultMessage: "Remind your {numTeams, plural, one{team} other{teams} } to post to Facebook and Instagram today!",
          serverLocale
        },
        { numTeams: p.staff.length }
      );

      messageInfo = {
        uniquelyRelevantTeamId,
        routerOpenAction: "send-reminder",
        title: translateServer({ defaultMessage: "Fundraiser: Social Day", serverLocale }),
        emailMessage: message,
        message: [message, messageCTA].join(" ")
      };
    } else if (p.reportToSend === "finalDay") {
      const message = translateServer({
        defaultMessage: "Fundraising ends tomorrow at midnight! Raise those funds.",
        serverLocale
      });

      messageInfo = {
        uniquelyRelevantTeamId,
        routerOpenAction: "send-reminder",
        title: translateServer({ defaultMessage: "Last Fundraiser Day!", serverLocale }),
        emailMessage: message,
        message: [message, messageCTA].join(" ")
      };
    } else {
      //generic
      if (p.daysBeforeEnd && p.daysBeforeEnd === 7) {
        const message = translateServer(
          { defaultMessage: "Only one week left! Raise those funds!", serverLocale },
          { daysBeforeEnd: p.daysBeforeEnd }
        );

        messageInfo = {
          uniquelyRelevantTeamId,
          routerOpenAction: "send-reminder",
          title: translateServer({ defaultMessage: "Fundraiser: One Week Left!", serverLocale }),
          emailMessage: message,
          message: [message, messageCTA].join(" ")
        };
      } else {
        const message = translateServer(
          {
            defaultMessage:
              "Your {numTeams, plural, one{team has} other{teams have}} raised {dollarStr}. Keep encouraging everyone!",
            serverLocale
          },
          { dollarStr, numTeams: p.staff.length }
        );
        const thisMessageCTA = translateServer({ defaultMessage: "Tap this notification to send a reminder.", serverLocale });

        messageInfo = {
          uniquelyRelevantTeamId,
          routerOpenAction: "send-reminder",
          title: translateServer({
            defaultMessage: "Fundraiser Progress",
            serverLocale
          }),
          emailMessage: message,
          message: [message, thisMessageCTA].join(" ")
        };
      }
    }
  } else if (p.selfAthlete?.length) {
    const goodEmojis = ["🙌", "👏", "👊", "🎉", "😤", "💪", "🤑", "👏", "💯", "🏆", "🥇", "👌"];
    const badEmojis = ["🤨", "🧐", "😬", "😱", "😞"];
    const totalRaised = p.selfAthlete.reduce((a, b) => a + b.totalDonationCents, 0);
    const dollarStr = formatMoneyCentsToDollarCentPrettyString(
      p.selfAthlete.reduce((a, b) => a + b.totalDonationCents, 0),
      true
    );

    const uniquelyRelevantTeamId = _(p.selfAthlete)
      .map(a => a.teamId)
      .uniq()
      .thru(a => (a.length === 1 ? a[0] : undefined))
      .value();

    const uniquelyRelevantPlayerId = _(p.selfAthlete)
      .map(a => a.playerId)
      .uniq()
      .thru(a => (a.length === 1 ? a[0] : undefined))
      .value();

    const emoji = _.shuffle(totalRaised > 0 ? goodEmojis : badEmojis).pop()!;

    if (p.reportToSend === "emailDay") {
      const message = translateServer(
        {
          defaultMessage: "You've raised {dollarStr} {emoji}. Send some emails and let's make some $$$.",
          serverLocale
        },
        { dollarStr, emoji }
      );
      messageInfo = {
        uniquelyRelevantPlayerId,
        uniquelyRelevantTeamId,
        routerOpenAction: "generic-share",
        title: translateServer({ defaultMessage: "Fundraiser: Email 10 people!", serverLocale }),
        message: [message, genericMessageCTA].join(" "),
        emailMessage: message
      };
    } else if (p.reportToSend === "textDay") {
      const message = translateServer(
        {
          defaultMessage: "You've raised {dollarStr} {emoji}. Quit scrolling social and start winning some prizes!",
          serverLocale
        },
        { dollarStr, emoji }
      );

      messageInfo = {
        uniquelyRelevantPlayerId,
        uniquelyRelevantTeamId,
        routerOpenAction: "sms-share",
        title: translateServer({ defaultMessage: "Fundraiser: Text 10 people!", serverLocale }),
        message: [message, genericMessageCTA].join(" "),
        emailMessage: message
      };
    } else if (p.reportToSend === "socialMediaDay") {
      const message = translateServer(
        {
          defaultMessage: "You've raised {dollarStr} {emoji}. Raise more by posting to social!",
          serverLocale
        },
        { dollarStr, emoji }
      );

      messageInfo = {
        uniquelyRelevantPlayerId,
        uniquelyRelevantTeamId,
        routerOpenAction: "generic-share",
        title: translateServer({ defaultMessage: "Fundraiser: Share to Social!", serverLocale }),
        message: [message, genericMessageCTA].join(" "),
        emailMessage: message
      };
    } else if (p.reportToSend === "finalDay") {
      const message = translateServer(
        {
          defaultMessage: "You've raised {dollarStr}. Today is your last chance to earn some prizes!",
          serverLocale
        },
        { dollarStr }
      );
      messageInfo = {
        uniquelyRelevantPlayerId,
        uniquelyRelevantTeamId,
        routerOpenAction: "generic-share",
        title: translateServer({ defaultMessage: "Fundraiser: Last Day!", serverLocale }),
        message: [message, genericMessageCTA].join(" "),
        emailMessage: message
      };
    } else {
      //generic
      if (p.daysBeforeEnd && p.daysBeforeEnd === 7) {
        const message = translateServer(
          {
            defaultMessage: "Only one week left and you've raised {dollarStr} {emoji}. We are almost there!",
            serverLocale
          },
          { dollarStr, emoji, daysBeforeEnd: p.daysBeforeEnd }
        );
        messageInfo = {
          uniquelyRelevantPlayerId,
          uniquelyRelevantTeamId,
          routerOpenAction: "generic-share",
          title: translateServer({ defaultMessage: "Fundraiser: One Week Left!", serverLocale }),
          message: [message, genericMessageCTA].join(" "),
          emailMessage: message
        };
      } else {
        const message = translateServer(
          {
            defaultMessage: "You've raised {dollarStr} {emoji}. Raise some $$$ and be the fundraising GOAT.",
            serverLocale
          },
          { emoji, dollarStr }
        );
        messageInfo = {
          uniquelyRelevantPlayerId,
          uniquelyRelevantTeamId,
          routerOpenAction: "generic-share",
          title: translateServer({ defaultMessage: "Fundraiser: Share, Share, Share!", serverLocale }),
          message: [message, genericMessageCTA].join(" "),
          emailMessage: message
        };
      }
    }
  } else if (p.guardian?.length) {
    const uniquelyRelevantTeamId = _(p.guardian)
      .map(a => a.teamId)
      .uniq()
      .thru(a => (a.length === 1 ? a[0] : undefined))
      .value();
    const uniquelyRelevantPlayerId = _(p.guardian)
      .map(a => a.playerId)
      .uniq()
      .thru(a => (a.length === 1 ? a[0] : undefined))
      .value();

    const uniqueFirstNames = _(p.guardian)
      .map(a => a.accountInfo.firstName)
      .uniq()
      .value();

    const whoHelping =
      uniqueFirstNames.length === 1
        ? uniqueFirstNames[0].trim()
        : translateServer({ defaultMessage: "your kids", description: "the kids that you are a parent for.", serverLocale });

    const dollarStr = formatMoneyCentsToDollarCentPrettyString(
      p.guardian.reduce((a, b) => a + b.totalDonationCents, 0),
      true
    );

    if (p.reportToSend === "emailDay") {
      const message = translateServer(
        {
          defaultMessage: "Help {whoHelping} raise $$$. Email their link to 10 people today! You've raised {dollarStr}.",
          description: 'The "whoHelping" variable can be a name "Timmy" or a phrase like "your kids"',
          serverLocale
        },
        { whoHelping, dollarStr }
      );

      messageInfo = {
        uniquelyRelevantPlayerId,
        uniquelyRelevantTeamId,
        routerOpenAction: "generic-share",
        title: translateServer({ defaultMessage: "Fundraiser: Email Day", serverLocale }),
        message: [message, genericMessageCTA].join(" "),
        emailMessage: message
      };
    } else if (p.reportToSend === "textDay") {
      const message = translateServer(
        {
          defaultMessage: "Youth sports are expensive. Help {whoHelping} and text 10 people today. You've raised {dollarStr}.",
          serverLocale,
          description: 'The "whoHelping" variable can be a name "Timmy" or a phrase like "your kids"'
        },
        { whoHelping, dollarStr }
      );

      const thisCTA = translateServer({ defaultMessage: "Tap here to send some texts.", serverLocale });

      messageInfo = {
        uniquelyRelevantPlayerId,
        uniquelyRelevantTeamId,
        routerOpenAction: "sms-share",
        title: translateServer({ defaultMessage: "Fundraiser: Time to Text!", serverLocale }),
        message: [message, thisCTA].join(" "),
        emailMessage: message
      };
    } else if (p.reportToSend === "socialMediaDay") {
      const message = translateServer(
        {
          defaultMessage:
            "Get help with the cost of youth sports. Post to Facebook and Instagram for {whoHelping} today. You've raised {dollarStr}.",
          description: 'The "whoHelping" variable can be a name "Timmy" or a phrase like "your kids"',
          serverLocale
        },
        { whoHelping, dollarStr }
      );

      messageInfo = {
        uniquelyRelevantPlayerId,
        uniquelyRelevantTeamId,
        routerOpenAction: "generic-share",
        title: translateServer({ defaultMessage: "Fundraiser: Social Day!", serverLocale }),
        message: [message, genericMessageCTA].join(" "),
        emailMessage: message
      };
    } else if (p.reportToSend === "finalDay") {
      const message = translateServer(
        {
          defaultMessage:
            "It's almost over! Help {whoHelping} by sharing via social, text, or email to any remaining people in your network!",
          serverLocale
        },
        { whoHelping }
      );
      messageInfo = {
        uniquelyRelevantPlayerId,
        uniquelyRelevantTeamId,
        routerOpenAction: "generic-share",
        title: translateServer({ defaultMessage: "Fundraiser: Last Day!", serverLocale }),
        message: [message, genericMessageCTA].join(" "),
        emailMessage: message
      };
    } else if (p.reportToSend === "generic") {
      //generic
      if (p.daysBeforeEnd && p.daysBeforeEnd === 7) {
        const message = translateServer(
          {
            defaultMessage:
              "It takes a village to pay for youth sports 🙂. One week left to get some help. You've raised {dollarStr}.",
            serverLocale
          },
          { dollarStr, whoHelping }
        );
        messageInfo = {
          uniquelyRelevantPlayerId,
          uniquelyRelevantTeamId,
          routerOpenAction: "generic-share",
          title: translateServer({ defaultMessage: "Fundraiser: One Week Left!", serverLocale }),
          message: [message, genericMessageCTA].join(" "),
          emailMessage: message
        };
      } else {
        const message = translateServer(
          {
            defaultMessage:
              "You've raised {dollarStr} for {whoHelping}. Share more to raise $$$ to help with sports fees this year.",
            serverLocale
          },
          { whoHelping, dollarStr }
        );
        messageInfo = {
          uniquelyRelevantPlayerId,
          uniquelyRelevantTeamId,
          routerOpenAction: "generic-share",
          title: translateServer({ defaultMessage: "Fundraiser: Share, Share Share", serverLocale }),
          message: [message, genericMessageCTA].join(" "),
          emailMessage: message
        };
      }
    } else {
      ((a: never) => {})(p.reportToSend);
    }
  }

  return messageInfo ?? null;
}

function getNotificationGeneratorInfo(p: {
  idempotencyKey: string;
  message: string;
  emailMessage: string;
  title: string;
  expireAtMS: number;
  routerPath: string;
  shareUrl?: string;
  accountEmail: string;
}) {
  const { expireAtMS, idempotencyKey, message, title, accountEmail, routerPath, shareUrl } = p;
  return {
    idempotencyKey,
    notificationType: NotificationType.lowPriorityNotification,
    type: "lowPriorityNotification" as const,
    lowPriorityNotificationDetail: {
      body: message,
      title: title,
      expireAtMS,
      routerPath,
      type: LowPriorityNotificationDetailType.fundraiserProgress
    },
    realTimeNotification: {
      e: expireAtMS
    },
    pushNotificationData: {
      body: message,
      title,
      lowPriorityNotificationDetailType: LowPriorityNotificationDetailType.fundraiserProgress,
      pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
      routerPath
    },
    emailData: {
      type: "normal",
      email: accountEmail,
      templateId: "d-48d3d97f1dd84db585d4648e57b1460c",
      unsubscribeGroup: 19514,
      templateData: {
        title,
        message: p.emailMessage,
        shareUrl
      }
    } as EmailDataNormal
  };
}
