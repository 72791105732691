import { ORG_FUNDRAISER_VISIBILITY_STATUS, TeamFundraiserBasicData, TeamId } from "@ollie-sports/models";
import { getFlexPrismaClient } from "../../helpers";
import _ from "lodash";
import moment from "moment";

export async function flex__server__getTeamFundraisers(p: { teamIds: TeamId[] }): Promise<TeamFundraiserBasicData[]> {
  const prismaClient = getFlexPrismaClient();

  const teamMappings = await prismaClient.ollie_team_mapping.findMany({
    where: {
      ollie_team_id: {
        in: p.teamIds
      }
    }
  });

  const flexTeamIds = _.compact(teamMappings.map(tm => Number(tm.flex_team_id)));

  const allFundraisers = await prismaClient.fundraisers.findMany({
    where: {
      team_id: {
        in: flexTeamIds
      }
    }
  });

  return _.compact(
    allFundraisers.map(f => {
      const teamMapping = teamMappings.find(m => Number(m.flex_team_id) === Number(f.team_id));
      if (teamMapping) {
        return {
          teamId: teamMapping.ollie_team_id ?? "",
          flexTeamId: Number(teamMapping.flex_team_id),
          fundraiserId: Number(f.id),
          orgFundraiserId: teamMapping.ollie_org_fundraiser_id === null ? undefined : Number(teamMapping.ollie_org_fundraiser_id),
          fundraiserStatus: f.status as "active" | "inactive",
          fundraiserVisibilityStatus: teamMapping.fundraiser_visibility_status as ORG_FUNDRAISER_VISIBILITY_STATUS,
          fundraiserName: f.name,
          endDateMS: moment(teamMapping.fundraiser_end_date).valueOf(),
          type: "team",
          teamSponsorshipPlayerPercent: Number(teamMapping.team_sponsorship_player_percent ?? 0),
          fundsGoToTeam: teamMapping.funds_go_to_team
        };
      }
      return null;
    })
  );
}

flex__server__getTeamFundraisers.auth = () => {};
