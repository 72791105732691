// getFinalRaffleTicketCountForEntireOrg
import { getFlexPrismaClient, getServerHelpers } from "../../helpers";
import { flex__server__getOrgFundraiserData } from "./flex__getOrgFundraiserData";
import {
  FundraiserFinalPlayerRaffleTotalDetails,
  FundraiserFinalPlayerRaffleTotalDetailsPlusPrizeWon,
  PlayerId,
  PRIZE_TYPES
} from "@ollie-sports/models";
import { org } from "../../serverTools";
import { flex__server__getPlayerFundraiserData } from "./flex__getPlayerFundraiserData";
import { flex__server__getTeamFundraiserData } from "./flex__getTeamFundraiserData";
import _ from "lodash";
import { getFinalRaffleTicketCountForTeam } from "./helpers/getFinalRaffeTicketCountForTeam";
import { chooseRaffleWinners } from "./helpers/chooseRaffleWinners";

export async function flex__server__getFinalRaffleTicketCountForOrgFundraiser(p: {
  orgFundraiserId: number;
  prizeCSV: string;
}): Promise<{
  winners: FundraiserFinalPlayerRaffleTotalDetailsPlusPrizeWon[];
  allRaffleTicketDetails: FundraiserFinalPlayerRaffleTotalDetails[];
}> {
  const prismaClient = getFlexPrismaClient();

  const [allTeamMappings, orgFundraisingData] = await Promise.all([
    prismaClient.ollie_team_mapping.findMany({
      where: {
        ollie_org_fundraiser_id: p.orgFundraiserId
      }
    }),
    await flex__server__getOrgFundraiserData({
      orgFundraiserId: p.orgFundraiserId
    })
  ]);

  if (!orgFundraisingData) {
    return {
      allRaffleTicketDetails: [],
      winners: []
    };
  }

  const orgPrizes = orgFundraisingData.orgPrizes;
  const orgPlayers = orgFundraisingData.topPlayers;
  const orgTeams = orgFundraisingData.teamAmountsRaised;

  const results = await Promise.all(
    allTeamMappings.map(tm => {
      const teamId = tm.ollie_team_id ?? "";
      const flexTeamId = Number(tm.flex_team_id ?? 0);
      return getFinalRaffleTicketCountForTeam({
        flexTeamId,
        teamId,
        orgPlayers,
        orgPrizes,
        orgFundraiserId: p.orgFundraiserId,
        orgTeams
      });
    })
  );

  const allRaffleTotals = _.flatten(results);

  const winners = chooseRaffleWinners({
    allRaffleTotals,
    prizeCSV: p.prizeCSV
  });

  return {
    allRaffleTicketDetails: allRaffleTotals,
    winners
  };
}

flex__server__getFinalRaffleTicketCountForOrgFundraiser.auth = () => {};
