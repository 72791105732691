import { getFlexPrismaClient } from "../../helpers";

export async function flex__server__updateTeamGoal(p: { fundraiserId: number; newGoal: number }) {
  const prismaClient = getFlexPrismaClient();
  await prismaClient.fundraisers.update({
    where: {
      id: p.fundraiserId
    },
    data: { goal_amount: p.newGoal }
  });
}

flex__server__updateTeamGoal.auth = () => {};
