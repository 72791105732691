import { OrgFundraiserBasicData, OrgId, ORG_FUNDRAISER_VISIBILITY_STATUS } from "@ollie-sports/models";
import { fundraisers, ollie_team_mapping, users } from "@prisma/client";
import { getFlexPrismaClient, getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import moment from "moment";
import { flex__server__getOrgFundraiserData } from "./flex__getOrgFundraiserData";
import { flex__server__getOrgFundraisers } from "./flex__getOrgFundraisers";

export async function flex__server__getOrgFundraiser(p: { orgFundraiserId: number }): Promise<OrgFundraiserBasicData | null> {
  const prismaClient = getFlexPrismaClient();
  const ollieOrgFundraisers = await prismaClient.$queryRaw<
    {
      endDate: Date;
      flexTeamId: number;
      fundraiserVisibilityStatus: ORG_FUNDRAISER_VISIBILITY_STATUS;
      orgFundraiserId: number;
      fundraiserStatus: "active" | "inactive";
      fundraiserName: string;
      orgId: OrgId;
      orgSponsorshipPlayerPercent: number;
      teamSponsorshipPlayerPercent: number;
      orgCommissionPercent: number;
      orgGoal: number;
      fundsGoToTeam: boolean;
    }[]
  >`select oofm.fundraiser_end_date          as endDate,
oofm.flex_team_id                 as flexTeamId,
oofm.fundraiser_visibility_status as fundraiserVisibilityStatus,
oofm.funds_go_to_team as fundsGoToTeam,
oofm.id                           as orgFundraiserId,
f.status                          as fundraiserStatus,
f.name                            as fundraiserName,
oofm.ollie_org_id                 as orgId,
oofm.org_sponsorship_player_percent as orgSponsorshipPlayerPercent,
oofm.team_sponsorship_player_percent as teamSponsorshipPlayerPercent,
oofm.org_commission_percent as orgCommissionPercent,
oofm.org_fundraiser_goal as orgGoal
from ollie_org_fundraiser_mapping oofm,
fundraisers f
where f.team_id = oofm.flex_team_id
and oofm.id = ${p.orgFundraiserId};`;

  if (!ollieOrgFundraisers.length) {
    return null;
  }

  return {
    endDateMS: moment(ollieOrgFundraisers[0].endDate).valueOf(),
    type: "org",
    ...ollieOrgFundraisers[0]
  };
}

flex__server__getOrgFundraiser.auth = () => {};
