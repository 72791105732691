import { OrgFundraiserBasicData, OrgId, ORG_FUNDRAISER_VISIBILITY_STATUS } from "@ollie-sports/models";
import { getFlexPrismaClient } from "../../helpers";
import _ from "lodash";
import moment from "moment";
import { Prisma } from "@prisma/client";

export async function flex__server__getOrgFundraisers(p: { orgIds: OrgId[] }): Promise<OrgFundraiserBasicData[]> {
  if (!p.orgIds.length) {
    return [];
  }
  const prismaClient = getFlexPrismaClient();

  const ollieOrgFundraisers = p.orgIds.length
    ? await prismaClient.$queryRaw<
        {
          endDate: Date;
          flexTeamId: number;
          fundraiserVisibilityStatus: ORG_FUNDRAISER_VISIBILITY_STATUS;
          orgFundraiserId: number;
          fundraiserStatus: "active" | "inactive";
          fundraiserName: string;
          orgId: OrgId;
          orgSponsorshipPlayerPercent: number;
          teamSponsorshipPlayerPercent: number;
          orgCommissionPercent: number;
          orgGoal: number;
          fundsGoToTeam: boolean;
        }[]
      >`select oofm.fundraiser_end_date          as endDate,
  oofm.flex_team_id                 as flexTeamId,
  oofm.fundraiser_visibility_status as fundraiserVisibilityStatus,
  oofm.id                           as orgFundraiserId,
  f.status                          as fundraiserStatus,
  f.name                            as fundraiserName,
  oofm.ollie_org_id                 as orgId,
  oofm.funds_go_to_team                 as fundsGoToTeam,
  oofm.org_fundraiser_image_url     as orgFundraiserImageUrl,
  oofm.org_sponsorship_player_percent as orgSponsorshipPlayerPercent,
  oofm.team_sponsorship_player_percent as teamSponsorshipPlayerPercent,
  oofm.org_commission_percent as orgCommissionPercent,
  oofm.org_fundraiser_goal as orgGoal
from ollie_org_fundraiser_mapping oofm,
fundraisers f
where f.team_id = oofm.flex_team_id
and oofm.ollie_org_id in (${Prisma.join(p.orgIds)});`
    : [];

  return ollieOrgFundraisers.map(oofm => {
    return {
      endDateMS: moment(oofm.endDate).valueOf(),
      type: "org" as const,
      ...oofm
    };
  });
}

flex__server__getOrgFundraisers.auth = () => {};
