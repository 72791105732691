import { PlayerFundsRaisedInfo, TeamId } from "@ollie-sports/models";
import { Prisma, PrismaClient } from "@prisma/client";
import { getFlexPrismaClient } from "../../../helpers";

//A function for getting adjusted donations due to sponsorship purchases that should go to players, teams, clubs, etc
export async function getFundsBreakdown(p: {
  flexTeamIds: number[];
  ollieCommissionPercentages: {
    donations: number;
    teamSponsorship: number;
    clubSponsorship: number;
  };
}) {
  const prismaClient = getFlexPrismaClient();
  const results = await prismaClient.$queryRaw<PlayerFundsRaisedInfo[]>`
  select e.referred_by,
       coalesce(e.first_name, 'No Player')                         as firstName,
       coalesce(e.last_name, 'Selected')                          as lastName,
       ROUND(sum(e.player_amount) / 100, 2) as playerAmount,
       ROUND(sum(e.team_amount) / 100, 2)   as teamAmount,
       ROUND(sum(e.club_amount) / 100, 2)   as clubAmount,
       ROUND(sum(e.total_amount) / 100, 2)  as totalAmount,
       e.team_name                          as teamName,
       e.ollie_team_id                      as teamId
from (select (case when d.referred_by is null or d.referred_by = 0 then 0
                  when lower(d.product_name) like '%club%sponsorship%'
                      then d.total_amount * d.org_sponsorship_player_percent * ${1 - p.ollieCommissionPercentages.clubSponsorship}
                  when lower(d.product_name) like '%team%sponsorship%'
                      then d.total_amount * d.team_sponsorship_player_percent * ${
                        1 - p.ollieCommissionPercentages.teamSponsorship
                      }
                  else d.total_amount  * ${1 - p.ollieCommissionPercentages.donations} end)                      player_amount,
             (case
                  when lower(d.product_name) like '%club%sponsorship%' then 0
                  when lower(d.product_name) like '%team%sponsorship%' and d.referred_by is not null and d.referred_by > 0
                      then (1- d.team_sponsorship_player_percent) * d.total_amount * ${
                        1 - p.ollieCommissionPercentages.teamSponsorship
                      }
                      when d.referred_by is null or d.referred_by = 0
                      then d.total_amount * ${1 - p.ollieCommissionPercentages.donations}
                  else 0 end)                                         team_amount,
             (case
                  when lower(d.product_name) like '%club%sponsorship%' then
                          (1 - d.org_sponsorship_player_percent) * d.total_amount * ${
                            1 - p.ollieCommissionPercentages.clubSponsorship
                          } +
                          (d.total_amount * d.org_commission_percent)
                  when lower(d.product_name) like '%team%sponsorship%'
                      then d.total_amount * d.org_commission_percent
                  else d.total_amount * d.org_commission_percent end) club_amount,
             d.first_name,
             d.last_name,
             d.referred_by,
             d.total_amount,
             d.team_name,
             d.ollie_team_id
      from (
               select coalesce(c.donation_total, c.amount) as total_amount,
                      c.referred_by,
                      c.product_name,
                      ofm.org_sponsorship_player_percent,
                      ofm.team_sponsorship_player_percent,
                      ofm.org_commission_percent,
                      c.first_name,
                      c.last_name,
                      c.ollie_team_id,
                      c.team_name
               from (select *
                     from (select don.referred_by,
                                  don.amount,
                                  otm.ollie_org_fundraiser_id,
                                  p.invoice_id as invoice_id_1,
                                  t.name       as team_name,
                                  otm.ollie_team_id
                           from donations don,
                                payments p,
                                fundraisers f,
                                teams t,
                                ollie_team_mapping otm
                           where t.id in (${Prisma.join(p.flexTeamIds)})
                             and p.id = don.payment_id
                             and don.fundraiser_id = f.id
                             and f.team_id = t.id
                             and t.id = otm.flex_team_id
                          ) a left join players pl on pl.id = a.referred_by
                              left join (select it.invoice_id     as invoice_id,
                                                pr.name           as product_name,
                                                pr.description    as product_description,
                                                pr.id             as product_id,
                                                it.donation_total as donation_total
                                         from invoice_items it,
                                              products pr
                                         where it.product_id = pr.id) b on a.invoice_id_1 = b.invoice_id
                    ) c
                        left join ollie_org_fundraiser_mapping ofm on ofm.id = c.ollie_org_fundraiser_id
           ) d) e
group by e.referred_by, e.first_name, e.last_name, e.team_name;
  `;

  const resultsByTeam = results.reduce((acc, val) => {
    if (!acc[val.teamId]) {
      acc[val.teamId] = [val];
    } else {
      acc[val.teamId] = [...acc[val.teamId], val];
    }
    return acc;
  }, {} as Record<TeamId, PlayerFundsRaisedInfo[]>);

  return resultsByTeam;
}
