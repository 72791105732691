import { TeamId, FundraisingPrize, PrettyPlayer, AccountInfo, PlayerId } from "@ollie-sports/models";
import moment from "moment";
import _ from "lodash";
import { getFlexPrismaClient } from "../../../helpers";
import { refreshFlexPlayer } from "./refreshFlexPlayer";

export async function maybeRefreshFlexPlayer(p: { accountInfo: AccountInfo; playerId: PlayerId }) {
  const prismaClient = getFlexPrismaClient();

  const playerMappings = await prismaClient.ollie_player_mapping.findMany({
    where: {
      ollie_player_id: p.playerId
    },
    select: {
      players: true,
      flex_player_id: true
    }
  });

  if (playerMappings.length) {
    await Promise.all(
      _.compact(
        playerMappings.map(pm => {
          if (!pm.players) {
            return null;
          }
          return refreshFlexPlayer({
            flexPlayerId: Number(pm.flex_player_id),
            flexUserId: Number(pm.players.user_id),
            playerInfo: p
          });
        })
      )
    );
  }
}
