import {
  AccountId,
  LowPriorityNotificationDetailType,
  NotificationType,
  PlayerId,
  PushNotificationSettingToRespect,
  TeamId
} from "@ollie-sports/models";
import { Request } from "express";
import moment from "moment";
import { getFlexPrismaClient } from "../../helpers";
import { generateAndProcessNotifications, NotificationGeneratorInfo__LowPriority } from "../notification/notification.plumbing";
import { getAccountIdsManagingPlayerIds } from "./helpers/getAccountIdsManagingPlayerIds";
import _ from "lodash";
import { ObjectKeys } from "../../utils";
import { type } from "os";
import { translate } from "@ollie-sports/i18n";

interface DonationNotificationData {
  playerId: PlayerId;
  donorName: string;
  message: string;
  donationId: number;
  flexTeamId: number;
  teamId: TeamId;
}
interface DonationNotificationDataWithAccountId extends DonationNotificationData {
  accountId: AccountId;
}

export async function flex__server__sendNewDonationNotifications(p: { nowMS: number }) {
  const prismaClient = getFlexPrismaClient();
  const nowMinute = moment(p.nowMS).minute();
  const startMoment = moment(p.nowMS)
    .startOf("minute")
    .subtract(nowMinute % 5, "minute")
    .subtract(5, "minute")
    .toDate();
  const endMoment = moment(p.nowMS)
    .startOf("minute")
    .add(5 - (nowMinute % 5), "minute")
    .subtract(5, "minute")
    .subtract(1, "millisecond")
    .toDate();

  const donationData = await prismaClient.$queryRaw<DonationNotificationData[]>`select opm.ollie_player_id as playerId,
  d.message as message,
  d.id as donationId,
  otm.flex_team_id as flexTeamId,
  otm.ollie_team_id as teamId
from donations d,
players p,
ollie_player_mapping opm,
ollie_team_mapping otm
where p.id = d.referred_by
and opm.flex_player_id = p.id
and otm.flex_team_id = p.team_id
and d.created_at > ${startMoment}
and d.created_at < ${endMoment};`;

  const playerIdToAccountIdsMapping = await getAccountIdsManagingPlayerIds({
    playerIds: _.uniq(donationData.map(dd => dd.playerId))
  });

  const notificationDataWithAccountId: DonationNotificationDataWithAccountId[] = donationData.reduce((acc, val) => {
    const accountIds = playerIdToAccountIdsMapping[val.playerId] ?? [];
    accountIds.forEach(accountId => {
      acc.push({ ...val, accountId });
    });
    return acc;
  }, [] as DonationNotificationDataWithAccountId[]);

  await generateAndProcessNotifications({
    data: notificationDataWithAccountId,
    generateFn: async ({ accountPrivate, data }) => {
      const ret: NotificationGeneratorInfo__LowPriority = {
        type: "lowPriorityNotification",
        notificationType: NotificationType.lowPriorityNotification,
        idempotencyKey: ["donation", data.accountId, data.donationId].join("|")
      };

      const locale = accountPrivate.communicationLocale ?? "en-us";

      const expireAtMS = Date.now() + 30 * 24 * 60 * 60 * 1000;
      const title = translate({ defaultMessage: "New donation!", serverLocale: locale });
      const body = data.message ?? translate({ defaultMessage: "Click to see details.", serverLocale: locale });
      const routerPath = `main/money/fundraisingTeamHomePage/${data.teamId}/${data.flexTeamId}/none`;

      ret.lowPriorityNotificationDetail = {
        type: LowPriorityNotificationDetailType.fundraiserDonation,
        body,
        title,
        expireAtMS,
        routerPath
      };

      ret.pushNotificationData = {
        body: body,
        title: title,
        pushNotificationSettingToRespect: PushNotificationSettingToRespect.ALWAYS_SEND,
        routerPath: routerPath,
        lowPriorityNotificationDetailType: LowPriorityNotificationDetailType.fundraiserDonation
      };

      ret.realTimeNotification = {
        e: expireAtMS
      };

      return ret;
    }
  });
}

flex__server__sendNewDonationNotifications.auth = (req: Request) => {
  if (req.get("x-magic-token") !== "LY:82vV$~uhk<N^") {
    throw new Error("Need magic token!");
  }
};
